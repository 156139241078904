import { IBodyProductSearch, ICatalogItem, ICatalogItemsState, TQuerySearchArray } from "interfaces/catalog.interface"
import { TinitialCommercialProfileState } from "interfaces/commercialProfile.interface"
import { MultimediaItemState } from "interfaces/multimedia.interface"

const bodyProductSearch: IBodyProductSearch = {
	options: {
		brand_id: [''],
		favorite: "",
		with_images: null,
		profile: false,
		search: [],
		language_id: "",
		order: [],
		group: [],
		limit: 30,
		index: 0
	},
	filter: {
		reference: [],
		color: [],
		season: [],
		segmentation: [],
		division: [],
		family: [],
		gender: [],
		pvi: [],
		pvpr: [],
		tags: [],
		tiers: [],
	}
}

const bodyProductSearchTemp: IBodyProductSearch = {
	options: {
		brand_id: [''],
		favorite: "",
		with_images: null,
		profile: false,
		search: [],
		language_id: "",
		order: [],
		group: [],
		limit: 30,
		index: 0
	},
	filter: {
		reference: [],
		color: [],
		season: [],
		segmentation: [],
		division: [],
		family: [],
		gender: [],
		pvi: [],
		pvpr: [],
		tags: [],
		tiers: [],
	}
}

const initialCommercialProfileState: TinitialCommercialProfileState = {
	banner: {} as MultimediaItemState,
	// bio: `Lacoste es una marca francesa de ropa y accesorios fundada en 1933 por el tenista René Lacoste y André Gillier. Conocida por su emblemático logo del cocodrilo verde, Lacoste se ha posicionado como una de las marcas líderes del sector de la moda con presencia global. Ofrece líneas de ropa casual-deportiva, calzado y artículos de piel que destacan por su diseño elegante, materiales de calidad y tradición artesanal. Sus prendas atemporales en colores clásicos están orientadas a un público joven, urbano y con estilo de vida activo que busca comfort y distinción. Lacoste ha evolucionado integrando innovación y vanguardia sin perder su esencia	clásica francesa.`,
	bio: '',
	products: [] as ICatalogItem[],
	videos: [] as MultimediaItemState[],
	getComercialProfileData: () => { },
	setBanner: () => { },
	initBio: () => { },
	updateBio: () => { },
	setProducts: () => { },
	removeProduct: () => { },
	setVideos: () => { },
	removeVideo: () => { },
	// Catalog
	catalogItems: {} as ICatalogItemsState,
	bodyProductSearch: bodyProductSearch,
	bodyProductSearchTemp: bodyProductSearchTemp,
	isLoading: true,
	isLoadingMoreProducts: false,
	thereAreMoreProducts: true,
	isEmptyProducts: false,
	getCatalog: () => { },
	addMoreProductsToCatalog: () => { },
	// Filters
	filter: () => { },
	addBodyFilters: () => { },
	removeBodyFilters: () => { },
	resetBodyFilters: () => { },
	setPriceSliders: () => { },
	setFavorites: () => { },
	resetPriceSlider: () => { },
	// Search engine
	querySearch: "",
	querysearchArray: [] as TQuerySearchArray[],
	setQuerySearch: () => { },
	addToBodySearch: () => { },
	addToQuerySearchArray: () => { },
	removeFromQuerySearchArray: () => { },
	removeFromBodySearch: () => { },
	removeAllFromBodySearch: () => { },
	removeFromBodySearchFilter: () => { },
	// Sort By
	setSortBy: () => { },
	setSortByHaveImages: () => { },
}

export default initialCommercialProfileState