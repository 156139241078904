import { IInitialCatalogDownloadState } from 'interfaces/catalogDownload.interface';
import * as CatalogDownloadType from './catalogDownload.types'

export default function catalogDownloadReducer(state: IInitialCatalogDownloadState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case CatalogDownloadType.SET_FILTERS_SELECTED:
			const setFiltersSelected: IInitialCatalogDownloadState = {
				...state,
				filtersSelected: payload
			}
			return setFiltersSelected

		case CatalogDownloadType.SET_IS_LOADING_CATALOG:
			const setIsLoadingCatalog: IInitialCatalogDownloadState = {
				...state,
				isLoadingCatalog: payload
			}
			return setIsLoadingCatalog

		case CatalogDownloadType.SET_FILTERED_LIST:
			const setFilteredList: IInitialCatalogDownloadState = {
				...state,
				filteredList: payload
			}
			return setFilteredList

		case CatalogDownloadType.SET_SELECTED_VALUES:
			const setSelectedValues: IInitialCatalogDownloadState = {
				...state,
				selectedValues: payload
			}
			return setSelectedValues

		case CatalogDownloadType.SET_IS_LOADING_FILTERS:
			const setIsLoadingFilters: IInitialCatalogDownloadState = {
				...state,
				isLoadingFilters: payload
			}
			return setIsLoadingFilters

		case CatalogDownloadType.SET_FILTERS:
			const setFilters: IInitialCatalogDownloadState = {
				...state,
				filters: payload
			}
			return setFilters

		case CatalogDownloadType.SET_PVI_SLIDER:
			const setPviSlider: IInitialCatalogDownloadState = {
				...state,
				catalogBodyFetch: {
					...state.catalogBodyFetch,
					filter: {
						...state.catalogBodyFetch.filter,
						pvi: payload
					}
				}
			}
			return setPviSlider

		case CatalogDownloadType.SET_PVPR_SLIDER:
			const setPvprSlider: IInitialCatalogDownloadState = {
				...state,
				catalogBodyFetch: {
					...state.catalogBodyFetch,
					filter: {
						...state.catalogBodyFetch.filter,
						pvpr: payload
					}
				}
			}
			return setPvprSlider

		case CatalogDownloadType.SET_FILTERS_ALERT:
			const setFiltersAlert: IInitialCatalogDownloadState = {
				...state,
				filtersAlert: payload
			}
			return setFiltersAlert

		case CatalogDownloadType.SET_FILTERS_TAGS:
			const setFiltersTags: IInitialCatalogDownloadState = {
				...state,
				filtersSelectedTags: payload
			}
			return setFiltersTags

		case CatalogDownloadType.SET_IS_OPEN_FILTERS:
			const setIsOpenFilters: IInitialCatalogDownloadState = {
				...state,
				isOpenFilters: payload
			}
			return setIsOpenFilters

		case CatalogDownloadType.SET_FILTERS_SELECTED_TEMP:
			const setFiltersSelectedTemp: IInitialCatalogDownloadState = {
				...state,
				filtersSelectedTemp: payload
			}
			return setFiltersSelectedTemp

		case CatalogDownloadType.SET_COLUMNS_ORDER:
			const setColumnsOrder: IInitialCatalogDownloadState = {
				...state,
				columnsOrder: payload
			}
			return setColumnsOrder

		case CatalogDownloadType.SET_DOWNLOADING_STATUS:
			const setDownloadingStatus: IInitialCatalogDownloadState = {
				...state,
				downloadingStatus: {
					isDownloading: payload.isDownloading ?? state.downloadingStatus.isDownloading,
					totalImagesToDownload: payload.totalImagesToDownload ?? state.downloadingStatus.totalImagesToDownload,
					completedDownloads: payload.completedDownloads ?? state.downloadingStatus.completedDownloads,
				}
			}
			return setDownloadingStatus

		case CatalogDownloadType.SET_IS_DOWNLOADING_ZIP:
			const setIsDownloadingZip: IInitialCatalogDownloadState = {
				...state,
				isDownloadingZip: payload
			}
			return setIsDownloadingZip

		case CatalogDownloadType.SET_DOWNLOAD_IMAGES:
			const setDownloadImages: IInitialCatalogDownloadState = {
				...state,
				downloadImages: payload
			}
			return setDownloadImages

		case CatalogDownloadType.SET_XHRS_REF:
			const setXhrsRef: IInitialCatalogDownloadState = {
				...state,
				xhrsRef: payload
			}
			return setXhrsRef

		case CatalogDownloadType.SET_QUERY_SEARCH:
			const setQuerySearch: IInitialCatalogDownloadState = {
				...state,
				querySearch: payload
			}
			return setQuerySearch

		case CatalogDownloadType.SET_IMAGE_SELECTION:
			const setImageSelection: IInitialCatalogDownloadState = {
				...state,
				imageSelection: payload
			}
			return setImageSelection

		case CatalogDownloadType.SET_FAVORITES:
			const setFavorites: IInitialCatalogDownloadState = {
				...state,
				catalogBodyFetch: {
					...state.catalogBodyFetch,
					options: {
						...state.catalogBodyFetch.options,
						favorite: payload
					}
				}
			}
			return setFavorites

		default:
			return state
	}
}