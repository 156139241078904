export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_COMPANY_USER_SUPER_ADMIN = 'LOGIN_COMPANY_USER_SUPER_ADMIN'
export const LOGIN_COMPANY_SUPER_ADMIN = 'LOGIN_COMPANY_SUPER_ADMIN'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR'
export const UPDATE_COMPANY_LOGO = 'UPDATE_COMPANY_LOGO'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const VERIFY_EMAIL = 'VERIFY_EMAIL'
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE'
export const SET_ASSOCIATIONS_STATES = 'SET_ASSOCIATIONS_STATES'
export const ADD_SOCIAL_MEDIA = 'ADD_SOCIAL_MEDIA'
export const SET_SOCIAL_MEDIA = 'SET_SOCIAL_MEDIA'
export const SET_IS_LOADING = 'SET_IS_LOADING'