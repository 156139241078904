import { IGenericType } from "interfaces/generics.interface"
import { basePath } from "./utils/config"
import { TValuesToAssign } from "interfaces/catalogCorrelations.interface"

export const getCatalogCorrelationsAPI = async (brandId: string, type: IGenericType, token: string): Promise<any> => {
  const params = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      brand_id: brandId,
      option: type,
      not_null: false
    })
  }
  const response = await fetch(`${basePath}/generic/get_correlation`, params)
  const data = await response.json()
  return { response, data }
}

export const assignGenericsAPI = async (brandId: string, values: TValuesToAssign, token: string): Promise<any> => {
  const params = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      brand_id: brandId,
      items: values
    })
  }
  const response = await fetch(`${basePath}/generic/assign_generic`, params)
  const data = await response.json()
  return { response, data }
}