import { createContext } from "react";

export const GoogleAddressContext = createContext({
	googleAddress: {
		address: '',
		postal_code: '',
		country_id: '',
		city: '',
		province: '',
		floor: '',
		door: '',
		coordinates: {
			lat: 0.0,
			lng: 0.0
		}
	},
	setGoogleAddress: (field: any, value: string) => { },
	autoSetGoogleAddress: (address: string, postal_code: string, country_id: string, city: string, province: string, floor: string, door: string, coordinates: any) => { },
	resetGoogleAddress: (values?: { address: string, postal_code: string, country_id: string, city: string, province?: string, floor?: string, door?: string, coordinates?: any }) => { }
});