import { ICSVMapping, ICSVMappingKey, IInitialCatalogCsvUploadState, IReportsBySeason, IReportsByUpload } from "interfaces/catalogCsvUpload.interface"

const initialcatalogCsvUploadState: IInitialCatalogCsvUploadState = {
	// Reports by upload
	getReportsByUpload: () => { },
	reportsByUpload: [] as IReportsByUpload[],
	isLoadingByUpload: false,
	// Reports by season
	getReportsBySeason: () => { },
	reportsBySeason: [] as IReportsBySeason[],
	isLoadingBySeason: false,
	// Publish upload
	publishUpload: () => { },
	isLoadingPublishUpload: {
		isLoading: false,
		uploadId: ''
	},
	// Reject upload
	rejectUpload: () => { },
	isLoadingRejectUpload: {
		isLoading: false,
		uploadId: ''
	},
	// Season
	setStartSeasonDate: () => { },
	setEndSeasonDate: () => { },
	enableSeason: () => { },
	isLoadingEnableSeason: {
		isLoading: false,
		seasonId: ''
	},
	// Upload CSV
	csvSelected: {
		papa: {} as Papa.ParseResult<unknown>,
		file: {} as any
	},
	setCSVSelected: () => { },
	isLoadingCSVSelected: false,
	setIsLoadingCSVSelected: () => { },
	saveCatalogCSVFile: () => Promise.resolve(false),
	isLoadingSaveCatalogCSVFile: false,
	// CSV mappings
	csvMappings: [] as ICSVMapping[],
	csvMappingKeys: [] as ICSVMappingKey[],
	getMappings: () => { },
	isLoadingCSVMappings: false,
	deleteMapping: () => { },
	createMapping: () => { },
}

export default initialcatalogCsvUploadState