import * as ComercialInfoTypes from './comercialInfo.types'
import * as api from 'api/commercialInfo.api';
import { commercialInfoError } from 'utils/errorCodes';
import { notificationError } from 'views/components/UI/notification';
import { messageError, messageSuccess, messageWarning } from 'views/components/UI/message';
import { CommercialInfo } from 'types';

export async function getCommercialInfoAction(dispatch: any, tenantId: string, token: string, translate: any) {
	try {
		let orderedCommercialInfo: Partial<CommercialInfo>[] = []
		const commercialInfoFetch = await api.getCommercialInfoAPI(tenantId, token)
		commercialInfoFetch.brand.comercial_agent_brands.forEach((item: any) => {
			const obj = {
				key: item.comercial_agent.id,
				email: item.comercial_agent.email,
				name: item.comercial_agent.name,
				lastname: item.comercial_agent.surname,
				tradeName: item.comercial_agent.company,
				phonePrefix: item.comercial_agent.telephone_prefix,
				phoneSufix: item.comercial_agent.telephone_sufix,
				salesGroup: item.comercial_agent.sales_group,
				sector: item.comercial_agent.sector,
				cif: item.comercial_agent.cif,
			}
			orderedCommercialInfo.push(obj)
		});
		return dispatch({
			type: ComercialInfoTypes.GET_COMERCIAL_INFO,
			payload: orderedCommercialInfo
		})
	} catch (err) {
		messageError('Error al obtener la información comercial.')
		notificationError({
			msg: translate('notification-error_message'),
			description: translate('notification-error_description'),
			errorCode: commercialInfoError.get_commercial_info_001
		})
	}
}

export async function selectComercialContactAction(dispatch: any, data: any, translate: any, token: string) {
	try {
		if (data.key) {
			const obj: any = []
			const getDivisions = await api.getDivisionsByAgentIdAPI(data.key, token)
			getDivisions?.comercial_agent_divisions?.forEach((item: any) => {
				obj.push(item.division.id)
			})
			data.divisions = obj
		}
		return dispatch({
			type: ComercialInfoTypes.SELECT_COMERCIAL_CONTACT,
			payload: data
		})
	} catch (err) {
		messageError('Error al seleccionar el agente.')
		notificationError({
			msg: translate('notification-error_message'),
			description: translate('notification-error_description'),
			errorCode: commercialInfoError.select_commercial_info_001
		})
	}
}

export async function createCommercialInfoContactAction(dispatch: any, brandId: string, contact: any, token: string, setIsOpenDrawer: (isOpen: boolean) => void, translate: any) {
	const body = {
		name: contact.name,
		surname: contact.surname,
		company: contact.company,
		email: contact.email,
		cif: contact.cif,
		sector: contact.sector,
		tel_prefix: contact.telephone_prefix,
		tel_sufix: contact.telephone_sufix,
		sales_group: contact.sales_group,
		divisions_id: contact.division,
	}

	try {
		const createFetch = await api.createCommercialAgentAPI(brandId, body, token)
		if (createFetch.response.status === 403) {
			messageWarning('El agente ya existe.')
			return notificationError({
				msg: translate('notification-error_message'),
				description: translate('notification-error_description'),
				errorCode: commercialInfoError.create_commercial_info_002
			})
		}
		const newContact = {
			key: createFetch.data.new_agent.id,
			email: createFetch.data.new_agent.email,
			name: createFetch.data.new_agent.name,
			lastname: createFetch.data.new_agent.surname,
			tradeName: createFetch.data.new_agent.company,
			phonePrefix: createFetch.data.new_agent.tel_prefix,
			phoneSufix: createFetch.data.new_agent.tel_sufix,
			divisions: createFetch.data.new_agent.divisions_id,
			salesGroup: createFetch.data.new_agent.sales_group,
			sector: createFetch.data.new_agent.sector,
			cif: createFetch.data.new_agent.cif,
		}
		dispatch({
			type: ComercialInfoTypes.CREATE_AGENT,
			payload: newContact
		})
		messageSuccess('Contacto creado correctamente.')
		return setIsOpenDrawer(false)
	} catch (err) {
		messageError('Error al crear al agente.')
		notificationError({
			msg: translate('notification-error_message'),
			description: translate('notification-error_description'),
			errorCode: commercialInfoError.create_commercial_info_001
		})
	}
}

export async function updateCommercialInfoContactAction(dispatch: any, contacts: any, newContact: any, token: string, setIsOpenDrawer: (isOpen: boolean) => void, translate: any) {
	try {
		const contactId = contacts.contactSelected.key
		const body = {
			filter: { id: contactId },
			update: {
				email: newContact.email,
				name: newContact.name,
				surname: newContact.surname,
				company: newContact.company,
				tel_prefix: newContact.telephone_prefix,
				tel_sufix: newContact.telephone_sufix,
				sector: newContact.sector,
				sales_group: newContact.sales_group,
			}
		}
		const updateFetch = await api.updateCommercialInfoAPI(body, token)
		if (updateFetch[0] === 1) {
			const findContact = contacts.contacts.findIndex((item: any) => item.key === contactId)
			const newContacts = [...contacts.contacts]
			newContacts[findContact].email = newContact.email
			newContacts[findContact].name = newContact.name
			newContacts[findContact].surname = newContact.surname
			newContacts[findContact].company = newContact.company
			newContacts[findContact].tel_prefix = newContact.telephone_prefix
			newContacts[findContact].tel_sufix = newContact.telephone_sufix
			newContacts[findContact].sector = newContact.sector
			newContacts[findContact].salesGroup = newContact.sales_group
			dispatch({
				type: ComercialInfoTypes.UPDATE_AGENT,
				payload: newContacts
			})
			messageSuccess('Contacto actualizado correctamente.')
			return setIsOpenDrawer(false)
		} else {
			messageError('Error al actualizar el agente.')
			notificationError({
				msg: translate('notification-error_message'),
				description: translate('notification-error_description'),
				errorCode: commercialInfoError.update_commercial_info_001
			})
		}
	} catch (err) {
		messageError('Error al actualizar el agente.')
		notificationError({
			msg: translate('notification-error_message'),
			description: translate('notification-error_description'),
			errorCode: commercialInfoError.update_commercial_info_002
		})
	}
}

export async function removeCommercialAgent(dispatch: any, contactId: string, contacts: any, token: string, translate: any) {
	try {
		const removeFetch = await api.removeCommercialAgentAPI(contactId, token)
		if (removeFetch.agents === 1) {
			const newContacts = [...contacts]
			const filteredContacts = newContacts.filter((item: any) => item.key !== contactId)
			dispatch({
				type: ComercialInfoTypes.REMOVE_AGENT,
				payload: filteredContacts
			})
			return messageSuccess('Agente comercial eliminado correctamente.')
		} else {
			messageError('Error al eliminar el agente comercial.')
			notificationError({
				msg: translate('notification-error_message'),
				description: translate('notification-error_description'),
				errorCode: commercialInfoError.remove_commercial_agent_001
			})
		}
	} catch (err) {
		messageError('Error al eliminar el agente comercial.')
		notificationError({
			msg: translate('notification-error_message'),
			description: translate('notification-error_description'),
			errorCode: commercialInfoError.remove_commercial_agent_002
		})
	}
}