import { TFiltersCatalog, ICatalogFetchCSVDataPlainSkuResponse, IInitialCatalogDownloadState, TCatalogDownloadFiltersSelected, TImageSelectionTypes, IBodyFilterCSVState } from "interfaces/catalogDownload.interface"
import { IColumnsSelectOrderState } from "views/pages/main/catalog/catalogDownloadPage/components/columnsSelectOrder/ColumnsSelectOrder";

const initialColumnsOrder: Record<string, IColumnsSelectOrderState> = {
	// brand: { show: true, required: true, order: 0 },
	reference: { show: true, required: true, order: 1 },
	color_code: { show: true, required: true, order: 2 },
	color: { show: true, required: false, order: 3 },
	size: { show: true, required: true, order: 4 },
	ean: { show: true, required: true, order: 5 },
	name: { show: false, required: false, order: 6 },
	pvi: { show: true, required: false, order: 7 },
	pvpr: { show: true, required: false, order: 8 },
	division: { show: false, required: false, order: 9 },
	gender: { show: false, required: false, order: 10 },
	season: { show: false, required: false, order: 11 },
	segmentation: { show: false, required: false, order: 12 },
	family: { show: false, required: false, order: 13 },
	material: { show: false, required: false, order: 14 },
	images: { show: true, required: false, order: 15 },
};

const body: IBodyFilterCSVState = {
	options: {
		brand_id: [],
		client_id: "",
		csv_format: true,
		favorite: "",
		with_images: null,
		search: [],
		language_id: "",
		order: [],
		group: [],
		limit: 200000,
		index: 0
	},
	filter: {
		reference: [],
		color: [],
		season: [],
		segmentation: [],
		division: [],
		family: [],
		gender: [],
		pvi: [],
		pvpr: [],
		tag: [],
		tier: [],
	}
}

const initialCatalogDownloadState: IInitialCatalogDownloadState = {
	// Filters
	catalogBodyFetch: body,
	filters: [] as TFiltersCatalog[],
	imageSelection: 'all' as TImageSelectionTypes,
	setImageSelection: () => { },
	setFavorites: () => { },
	setPviSlider: () => { },
	setPvprSlider: () => { },
	isLoadingFilters: false,
	filtersAlert: {
		description: '',
		message: '',
		show: false,
		type: 'info'
	},
	filtersSelected: {} as TCatalogDownloadFiltersSelected,
	isLoadingCatalog: false,
	filteredList: [] as ICatalogFetchCSVDataPlainSkuResponse[],
	setFilteredList: () => { },
	selectedValues: [] as React.Key[],
	setSelectedValues: () => { },
	filtersSelectedTags: [] as string[],
	isOpenFilters: false,
	filtersSelectedTemp: {} as TCatalogDownloadFiltersSelected,
	openFilters: () => { },
	cancelFilters: () => { },
	cleanFilters: () => { },
	applyFilterChanges: () => { },
	onChangeFilterSelect: () => { },
	// Search
	querySearch: '',
	setQuerySearch: () => { },
	applySearch: () => { },
	// Columns
	columnsOrder: initialColumnsOrder,
	moveColumnsOrder: () => { },
	checkColumn: () => { },
	// Download
	downloadingStatus: {
		isDownloading: false,
		totalImagesToDownload: 0,
		completedDownloads: 0,
	},
	downloadCatalog: () => { },
	cancelDownloadCatalog: () => { },
	isDownloadingZip: false,
	downloadImages: true,
	setDownloadImages: () => { },
	xhrsRef: [],
}

export default initialCatalogDownloadState