import { memo, useState } from "react";
import { MenuMobileContext } from "./MenuMobileContext";
import { ChildrenProps } from "types";

const MenuMobileProvider = ({ children }: ChildrenProps) => {
	const [isOpen, setIsOpen] = useState(false)

	const setIsOpenAction = (isOpen: boolean) => {
		setIsOpen(isOpen)
	}

	return (
		<MenuMobileContext.Provider value={{ isOpen, setIsOpenAction }}>
			{children}
		</MenuMobileContext.Provider>
	)
}

export default memo(MenuMobileProvider)