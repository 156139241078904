import { IGetCompanyInfoAPIResponse } from "./responseInterfaces/company.response.interface"
import { basePath } from "./utils/config"

export const getCompanyInfoAPI = async ({ tenantId, countryId, token }: { tenantId: string, countryId: string, token: string }): Promise<IGetCompanyInfoAPIResponse> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/brand_profile/get_tenant_profile?tenant_id=${tenantId}&country_id=${countryId}`, params)
	const data = await response.json()
	return { response, data }
}

export const updateCompanyDataAPI = async (body: { filter: { id: string }; update: any }, token: string): Promise<any> => {
	const params = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/brand_profile/update_brand_profile`, params)
	const data = await response.json()
	return { response, data }
}

export const updateTenantDataAPI = async (body: { filter: { id: string }; update: any }, token: string): Promise<any> => {
	const params = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/brand_profile/update_tenant_profile`, params)
	const data = await response.json()
	return { response, data }
}

export const addSocialMediaAPI = async (tenantId: string, items: any, token: string): Promise<any> => {
	const params = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			tenant_id: tenantId,
			items: items
		}),
	}
	const response = await fetch(`${basePath}/social_media/add_social_media`, params)
	const data = await response.json()
	return { response, data }
}

export const removeSocialMediaAPI = async (socialMediaId: string, token: string): Promise<any> => {
	const params = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			target: "tenant",
			ids: [socialMediaId]
		}),
	}
	const response = await fetch(`${basePath}/social_media/delete_social_media`, params)
	const data = await response.json()
	return { response, data }
}

export const updateSocialMediaAPI = async (tenant_id: string, id: string, url: string, name: string, token: string): Promise<any> => {
	const params = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			tenant_id: tenant_id,
			id: id,
			url: url,
			name: name
		}),
	}
	const response = await fetch(`${basePath}/social_media/update_social_media`, params)
	const data = await response.json()
	return { response, data }
}