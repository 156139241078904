import { IGenericType } from "interfaces/generics.interface"
import { basePath } from "./utils/config"
import { IgetLanguagesAPIResponse } from "./responseInterfaces/general.response.interface"

export const pingAPI = async (token: string): Promise<any> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/status/ping`, params)
	const data = await response.json()
	return { response, data }
}

export const getLanguagesAPI = async (token: string): Promise<IgetLanguagesAPIResponse> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			filter: {},
			select: "",
			limit: 200,
			index: 0
		}),
	}
	const response = await fetch(`${basePath}/crud_language/findManyCrud_language`, params)
	const data = await response.json()
	return { response, data }
}

export const getAssociationsStatesAPI = async (token: string): Promise<any> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/client_profile/get_association_states`, params)
	const data = await response.json()
	return { response, data }
}

export const getProductAttributesAPI = async (brandId: string, attribute: IGenericType, token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			brand_id: brandId,
			attribute: attribute
		}),
	}
	const response = await fetch(`${basePath}/product_management/get_product_attribute`, params)
	const data = await response.json()
	return { response, data }
}