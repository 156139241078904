import { ISAdminTenantState, ISAdminUserTenantState } from 'interfaces/superAdmin.interface';
import * as SuperAdminTypes from './superAdmin.types'
import { getClientsAPI } from 'api/superAdmin.api';
import { IBrandUser } from 'interfaces/brandUsers.interface';
import moment from 'moment';

export async function getClientsAction(dispatch: any, token: string, translate: any) {
  dispatch({ type: SuperAdminTypes.SET_IS_LOADING, payload: true })
  try {
    const getClientsFetch = await getClientsAPI(token)
    if (getClientsFetch.response.status === 200) {
      getClientsFetch.data.forEach((client: ISAdminTenantState) => {
        client.logo = client.logo ? `${client.logo}?ts=${moment().unix()}` : ""
      });
      dispatch({
        type: SuperAdminTypes.SET_CLIENTS,
        payload: getClientsFetch.data.length > 0 ? getClientsFetch.data : []
        // payload: getClientsFetch.data.filter((client: ISAdminTenantState) => client.user_tenants.length > 0)
      })
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: SuperAdminTypes.SET_IS_LOADING, payload: false })
  } finally {
    dispatch({ type: SuperAdminTypes.SET_IS_LOADING, payload: false })
  }
}

export async function removeUserClientAction(dispatch: any, clients: ISAdminTenantState[], tenantId: string, userId: string, translate: any) {
  const findIndexClient = clients.findIndex((client: ISAdminTenantState) => client.brand.id === tenantId)
  if (findIndexClient !== -1) {
    const userClients = clients[findIndexClient].user_tenants.filter((user: ISAdminUserTenantState) => user.user.id !== userId)
    clients[findIndexClient].user_tenants = userClients
    dispatch({
      type: SuperAdminTypes.SET_CLIENTS,
      payload: clients
    })
  }
}

export async function addUserClientAction(dispatch: any, clients: ISAdminTenantState[], tenantId: string, user: IBrandUser, translate: any) {
  const newUser: ISAdminUserTenantState = {
    id: user.key,
    user_id: user.key,
    tenant_id: tenantId,
    createdAt: undefined,
    updatedAt: undefined,
    user: {
      id: user.key,
      auth_id: user.auth_id,
      username: user.username,
      name: user.name,
      surname: user.surname,
      tel_prefix: "",
      tel_sufix: "",
      profile_image: "",
      i18n_lang_id: user.language,
      i18n_lang_code: "es",
      role: user.role,
      sale: "",
      stock: "",
      prediction: "",
      sector: user.sector,
      signed: user.signed,
      first_login: user.first_login,
      enabled: user.enabled,
      lastLoging: user.last_login,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt
    }
  }
  const findIndexClient = clients.findIndex((client: ISAdminTenantState) => client.id === tenantId)
  if (findIndexClient !== -1) {
    clients[findIndexClient].user_tenants.push(newUser)
    dispatch({
      type: SuperAdminTypes.SET_CLIENTS,
      payload: clients
    })
  }
}