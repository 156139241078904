import * as CommercialProfileTypes from './commercialProfile.types'
import { TinitialCommercialProfileState } from 'interfaces/commercialProfile.interface';

export default function commercialProfileReducer(state: TinitialCommercialProfileState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case CommercialProfileTypes.SET_BANNER:
			const setBanner: TinitialCommercialProfileState = {
				...state,
				banner: payload
			}
			return setBanner

		case CommercialProfileTypes.SET_BIO:
			const setBio: TinitialCommercialProfileState = {
				...state,
				bio: payload
			}
			return setBio

		case CommercialProfileTypes.SET_PRODUCTS:
			const setProducts: TinitialCommercialProfileState = {
				...state,
				products: payload
			}
			return setProducts

		case CommercialProfileTypes.REMOVE_PRODUCT:
			const removeProduct: TinitialCommercialProfileState = {
				...state,
				products: state.products.filter(product => product.id !== payload.id)
			}
			return removeProduct

		case CommercialProfileTypes.SET_VIDEOS:
			const setVideos: TinitialCommercialProfileState = {
				...state,
				videos: payload
			}
			return setVideos

		case CommercialProfileTypes.REMOVE_VIDEO:
			const removeVideo: TinitialCommercialProfileState = {
				...state,
				videos: state.videos.filter(video => video.id !== payload.id)
			}
			return removeVideo

		// Catalog
		case CommercialProfileTypes.GET_CATALOG:
			const getCatalog: TinitialCommercialProfileState = {
				...state,
				catalogItems: payload
			}
			return getCatalog

		case CommercialProfileTypes.SET_IS_LOADING:
			const setIsLoading: TinitialCommercialProfileState = {
				...state,
				isLoading: payload
			}
			return setIsLoading

		case CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH:
			const setBodyProductSearch: TinitialCommercialProfileState = {
				...state,
				bodyProductSearch: payload
			}
			return setBodyProductSearch

		case CommercialProfileTypes.SET_BODY_PRODUCT_SEARCH_TEMP:
			const setBodyProductSearchTemp: TinitialCommercialProfileState = {
				...state,
				bodyProductSearchTemp: payload
			}
			return setBodyProductSearchTemp

		case CommercialProfileTypes.INCREMENT_PAGINATION_INDEX:
			const incrementPaginationIndex: TinitialCommercialProfileState = {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						index: payload
					}
				}
			}
			return incrementPaginationIndex

		case CommercialProfileTypes.SET_THERE_ARE_MORE_PRODUCTS:
			const setThereAreMoreProducts: TinitialCommercialProfileState = {
				...state,
				thereAreMoreProducts: payload
			}
			return setThereAreMoreProducts

		case CommercialProfileTypes.SET_IS_EMPTY_PRODUCTS:
			const setIsEmptyProducts: TinitialCommercialProfileState = {
				...state,
				isEmptyProducts: payload
			}
			return setIsEmptyProducts

		case CommercialProfileTypes.SET_BODY_PRODUCT:
			const setBodyProduct: TinitialCommercialProfileState = {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						brand_id: [payload.brandId],
						limit: payload.limit,
						language_id: payload.languageId,
					},
					filter: {
						reference: [],
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tags: [],
						tiers: [],
					}
				},
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						brand_id: payload.brandId,
						limit: payload.limit,
						language_id: payload.languageId,
					},
					filter: {
						reference: [],
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tags: [],
						tiers: [],
					}
				},
				querysearchArray: []
			}
			return setBodyProduct

		case CommercialProfileTypes.ADD_MORE_TO_CATALOG:
			const addMoreToCatalog: TinitialCommercialProfileState = {
				...state,
				catalogItems: {
					...state.catalogItems,
					data: state.catalogItems.data.concat(payload.data)
				}
			}
			return addMoreToCatalog

		case CommercialProfileTypes.SET_IS_LOADING_MORE_PRODUCTS:
			const setIsLoadingMoreProducts: TinitialCommercialProfileState = {
				...state,
				isLoadingMoreProducts: payload
			}
			return setIsLoadingMoreProducts

		// Filters
		case CommercialProfileTypes.ADD_BODY_FILTERS:
			const addBodyFilters: TinitialCommercialProfileState = {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					filter: {
						...state.bodyProductSearchTemp.filter,
						[payload.field]: [...state.bodyProductSearchTemp.filter[payload.field as keyof typeof state.bodyProductSearchTemp.filter], payload.value]
					}
				}
			}
			return addBodyFilters

		case CommercialProfileTypes.REMOVE_BODY_FILTERS:
			const removeBodyFilters: TinitialCommercialProfileState = {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					filter: {
						...state.bodyProductSearchTemp.filter,
						[payload.field]: (state.bodyProductSearchTemp.filter[payload.field as keyof typeof state.bodyProductSearchTemp.filter] as string[]).filter((value) => value !== payload.value)
					}
				}
			}
			return removeBodyFilters

		case CommercialProfileTypes.RESET_BODY_FILTERS:
			const resetBodyFilters: TinitialCommercialProfileState = {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						favorite: "",
						// search: [""],
						index: 0
					},
					filter: {
						reference: [],
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tags: [],
						tiers: [],
					}
				},
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						favorite: "",
						// search: [""],
						index: 0
					},
					filter: {
						reference: [],
						color: [],
						season: [],
						segmentation: [],
						division: [],
						family: [],
						gender: [],
						pvi: [],
						pvpr: [],
						tags: [],
						tiers: [],
					}
				},
				querysearchArray: []
			}
			return resetBodyFilters

		case CommercialProfileTypes.SET_PRICE_SLIDERS:
			const setPriceSliders: TinitialCommercialProfileState = {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					filter: {
						...state.bodyProductSearchTemp.filter,
						[payload.field]: payload.value
					}
				}
			}
			return setPriceSliders

		case CommercialProfileTypes.SET_FAVORITES:
			const setFavorites: TinitialCommercialProfileState = {
				...state,
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					options: {
						...state.bodyProductSearchTemp.options,
						favorite: payload,
					}
				}
			}
			return setFavorites

		case CommercialProfileTypes.RESET_PRICE_SLIDER:
			const resetPriceSlider: TinitialCommercialProfileState = {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					filter: {
						...state.bodyProductSearch.filter,
						[payload.type]: []
					}
				},
				bodyProductSearchTemp: {
					...state.bodyProductSearchTemp,
					filter: {
						...state.bodyProductSearchTemp.filter,
						[payload.type]: []
					}
				},
			}
			return resetPriceSlider

		// Search engine
		case CommercialProfileTypes.SET_QUERY_SEARCH:
			const setQuerySearch: TinitialCommercialProfileState = {
				...state,
				querySearch: payload
			}
			return setQuerySearch

		case CommercialProfileTypes.ADD_TO_QUERY_SEARCH_ARRAY:
			const addToQuerySearchArray: TinitialCommercialProfileState = {
				...state,
				querysearchArray: !state.querysearchArray.includes(payload) ? [...state.querysearchArray, payload] : state.querysearchArray
			}
			return addToQuerySearchArray

		case CommercialProfileTypes.REMOVE_FROM_QUERY_SEARCH_ARRAY:
			const removeFromQuerySearchArray: TinitialCommercialProfileState = {
				...state,
				querysearchArray: state.querysearchArray.filter(item => item !== payload)
			}
			return removeFromQuerySearchArray

		case CommercialProfileTypes.SET_BODY_SEARCH_FILTER:
			const setBodySearchFilter: TinitialCommercialProfileState = {
				...state,
				querysearchArray: payload.querySearchArray,
			}
			return setBodySearchFilter

		// Sort By
		case CommercialProfileTypes.SET_SORT_BY:
			const setSortBy: TinitialCommercialProfileState = {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						order: [payload],
					}
				}
			}
			return setSortBy

		case CommercialProfileTypes.SET_SORT_BY_HAVE_IMAGES:
			const setSortByHaveImages: TinitialCommercialProfileState = {
				...state,
				bodyProductSearch: {
					...state.bodyProductSearch,
					options: {
						...state.bodyProductSearch.options,
						with_images: payload,
					}
				}
			}
			return setSortByHaveImages

		default:
			return state
	}
}