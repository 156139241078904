import { Suspense, lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { LoginRequired, SuperAdminRequired } from './middlewares'
import * as path from './paths'

// LAYOUTS
const MainLayout = lazy(() => import('../views/layouts/mainLayout'))
const AuthenticationLayout = lazy(() => import('../views/layouts/authLayout'))
const AdminLayout = lazy(() => import('views/layouts/adminLayout'))
const AccountLayout = lazy(() => import('../views/layouts/accountLayout'))
const ClientLayout = lazy(() => import('../views/layouts/clientLayout'))
const CatalogUploadsLayout = lazy(() => import('../views/layouts/catalogUploadsLayout'))
//// PAGES
// Main Pages
const DashboardSalePage = lazy(() => import('views/pages/main/dashboards/dashboardSalePage'))
const DashboardStockPage = lazy(() => import('views/pages/main/dashboards/dashboardStockPage'))
const DashboardPredictionPage = lazy(() => import('views/pages/main/dashboards/dashboardPredictionPage'))
const RegisterBrand = lazy(() => import('views/pages/main/registerBrandPage/RegisterBrandPage'))
const ThanksForRegister = lazy(() => import('views/pages/main/thanksForRegister/ThanksForRegister'))
const CatalogPage = lazy(() => import('views/pages/main/catalog/catalogPage'))
const AddCatalogPage = lazy(() => import('views/pages/main/catalog/addCatalogPage/AddCatalogPage'))
const MultimediaPage = lazy(() => import('views/pages/main/multimediaPage'))
const MultimediaVideosPage = lazy(() => import('views/pages/main/multimediaPage/multimediaVideos'))
const MultimediaImagesPage = lazy(() => import('views/pages/main/multimediaPage/multimediaImages'))
const MultimediaBannersPage = lazy(() => import('views/pages/main/multimediaPage/multimediaBanners'))
const ClientsPage = lazy(() => import('views/pages/main/clientsPage/clientsPage'))
const AddClientPage = lazy(() => import('views/pages/main/clientsPage/addClientPage'))
const ProductPage = lazy(() => import('views/pages/main/catalog/productPage'))
const NotificationsPage = lazy(() => import('views/pages/main/notificationsPage'))
const WholesaleFeedPage = lazy(() => import('views/pages/main/wholesaleFeedPage'))
const CommercialProfilePage = lazy(() => import('views/pages/main/commercialProfilePage'))
const CatalogUploadsByUploadPage = lazy(() => import('views/pages/main/catalog/catalogUploads/catalogUploadsByUploadPage'))
const CatalogUploadsBySeasonPage = lazy(() => import('views/pages/main/catalog/catalogUploads/catalogUploadsBySeasonPage'))
const AddCatalogPagePreview = lazy(() => import('views/pages/main/catalog/addCatalogPagePreview'))
const AddCatalogImagesPagePreview = lazy(() => import('views/pages/main/catalog/addCatalogPageImagesPreview'))
const CatalogCorrelation = lazy(() => import('views/pages/main/catalog/catalogCorrelation'))
const CatalogGeneric = lazy(() => import('views/pages/main/catalog/catalogGeneric'))
const CatalogDownloadPage = lazy(() => import('views/pages/main/catalog/catalogDownloadPage'))
// Auth Pages
const SignupPage = lazy(() => import('views/pages/authentication/signupPage'))
const LoginRedirect = lazy(() => import('views/pages/authentication/loginRedirect'))
// Profile pages
const ProfilePage = lazy(() => import('views/pages/main/accountPage/profilePage'))
// const CommercialInformationPage = lazy(() => import('views/pages/main/accountPage/commercialInformationPage'))
const CompanyDataPage = lazy(() => import('views/pages/main/accountPage/companyDataPage'))
const MainContactsPage = lazy(() => import('views/pages/main/accountPage/mainContactsPage'))
// Client
const ResumePage = lazy(() => import('views/pages/main/clientPage/resumePage'))
const BusinessInfoPage = lazy(() => import('views/pages/main/clientPage/businessInfoPage'))
const SalePointsPage = lazy(() => import('views/pages/main/clientPage/salePointsPage'))
// const MainContactsClientsPage = lazy(() => import('views/pages/main/clientPage/clientContactsPage'))
// const CommercialInfoPage = lazy(() => import('views/pages/main/clientPage/commercialInfoPage'))
//Config
const SettingsPage = lazy(() => import('views/pages/main/settingsPage'))
const UserConfig = lazy(() => import('views/pages/main/settingsPage/usersPage/UsersPage'))
// Admin
const AdminHome = lazy(() => import('views/pages/admin/adminHome'))
// Error
const ErrorXXX = lazy(() => import('views/pages/messages/errorXXX'))
const ComingSoon = lazy(() => import('views/pages/messages/comingSoon/ComingSoon'))

const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginRequired><Lazy><MainLayout /></Lazy></LoginRequired>,
    children: [
      { path: '/', element: <Lazy><DashboardSalePage /></Lazy> },
      { path: path.dashboardSale, element: <Lazy><DashboardSalePage /></Lazy> },
      { path: path.dashboardStock, element: <Lazy><DashboardStockPage /></Lazy> },
      { path: path.dashboardPrediction, element: <Lazy><DashboardPredictionPage /></Lazy> },
      // { path: 'dashboard/my-brands', element: <Lazy><MyBrandsPage /></Lazy>},
      // CATALOG
      { path: 'catalog', element: <Lazy><CatalogPage /></Lazy> },
      { path: 'catalog/:id', element: <Lazy><ProductPage /></Lazy> },
      {
        path: 'catalog-uploads',
        element: <Lazy> <CatalogUploadsLayout /> </Lazy>,
        children: [
          { path: 'by-upload', element: <Lazy> <CatalogUploadsByUploadPage /> </Lazy> },
          { path: 'by-season', element: <Lazy> <CatalogUploadsBySeasonPage /> </Lazy> }
        ]
      },
      { path: 'catalog-uploads/add-catalog', element: <Lazy> <AddCatalogPage /> </Lazy> },
      { path: 'catalog-uploads/add-catalog/preview', element: <Lazy> <AddCatalogPagePreview /> </Lazy> },
      { path: 'catalog-uploads/add-catalog/upload-images', element: <Lazy> <AddCatalogImagesPagePreview /> </Lazy> },
      { path: path.catalogCorrelation, element: <Lazy> <CatalogCorrelation /> </Lazy> },
      { path: path.catalogGeneric, element: <SuperAdminRequired><Lazy> <CatalogGeneric /> </Lazy></SuperAdminRequired> },
      { path: path.catalogDownload, element: <Lazy> <CatalogDownloadPage /> </Lazy> },
      // MULTIMEDIA
      {
        path: 'multimedia',
        element: <Lazy><MultimediaPage /></Lazy>,
        children: [
          { path: 'videos', element: <Lazy><MultimediaVideosPage /></Lazy> },
          { path: 'images', element: <Lazy><MultimediaImagesPage /></Lazy> },
          { path: 'banners', element: <Lazy><MultimediaBannersPage /></Lazy> },
          // { path: 'videos', element: <Lazy><ComingSoon /></Lazy> },
          // { path: 'images', element: <Lazy><ComingSoon /></Lazy> },
          // { path: 'banners', element: <Lazy><ComingSoon /></Lazy> },
        ]
      },
      // CLIENTS
      { path: 'clients', element: <Lazy><ClientsPage /></Lazy> },
      { path: 'add-client', element: <Lazy><AddClientPage /></Lazy> },
      {
        path: path.client,
        element: <Lazy><ClientLayout /></Lazy>,
        children: [
          { path: path.resume, element: <Lazy><ResumePage /></Lazy> },
          { path: path.businessInfo, element: <Lazy><BusinessInfoPage /></Lazy> },
          { path: path.salePoints, element: <Lazy><SalePointsPage /></Lazy> },
          { path: path.mainContacts, element: <Lazy><ComingSoon /></Lazy> },
          // { path: 'main-contacts', element: <Lazy><MainContactsClientsPage /></Lazy> },
          { path: path.commercialInfo, element: <Lazy><ComingSoon /></Lazy> },
          // { path: 'commercial-info', element: <Lazy><CommercialInfoPage /></Lazy> },
        ]
      },
      // ACCOUNT
      {
        path: path.account,
        element: <Lazy><AccountLayout /></Lazy>,
        children: [
          { path: path.profile, element: <Lazy><ProfilePage /></Lazy> },
          { path: path.company, element: <Lazy><CompanyDataPage /></Lazy> },
          { path: path.contacts, element: <Lazy><MainContactsPage /></Lazy> },
          { path: path.commercial, element: <Lazy><ComingSoon /></Lazy> },
          // { path: path.commercial, element: <Lazy><CommercialInformationPage /></Lazy> },
        ]
      },
      // CONFIG
      { path: path.settings, element: <Lazy><SettingsPage /></Lazy> },
      { path: path.settingsUsers, element: <Lazy><UserConfig /></Lazy> },
      { path: path.settingsChronos, element: <Lazy><ComingSoon /></Lazy> },
      { path: path.settingsDevices, element: <Lazy><ComingSoon /></Lazy> },
      { path: path.settingsTouchScreen, element: <Lazy><ComingSoon /></Lazy> },
      { path: path.settingsSystemSettings, element: <Lazy><ComingSoon /></Lazy> },
      { path: path.settingsCommercialProfile, element: <Lazy><CommercialProfilePage /></Lazy> },
      { path: path.settingsBilling, element: <Lazy><ComingSoon /></Lazy> },
      { path: path.settingsSupport, element: <Lazy><ComingSoon /></Lazy> },
      // NOTIFICATIONS
      { path: 'notifications', element: <Lazy><NotificationsPage /></Lazy> },
      // WHOLESALE FEED
      { path: 'wholesale-feed/:wholesaleId', element: <Lazy><WholesaleFeedPage /></Lazy> },
      // ERROR
      { path: '*', element: <Lazy><ErrorXXX code='404' /></Lazy> }
    ]
  },
  {
    path: 'authentication',
    element: <Lazy><AuthenticationLayout /></Lazy>,
    children: [
      { path: 'signup', element: <Lazy><SignupPage /></Lazy> },
      { path: 'signup-brand', element: <Lazy><RegisterBrand /></Lazy> },
      { path: 'thanks', element: <Lazy><ThanksForRegister /></Lazy> },
      { path: 'login', element: <Lazy><LoginRedirect /></Lazy> }
    ]
  },

  {
    path: 'admin',
    element: <SuperAdminRequired><Lazy><AdminLayout /></Lazy></SuperAdminRequired>,
    children: [
      { path: 'home', element: <Lazy><AdminHome /></Lazy> }
    ]
  }
])

function Lazy({ children }: any) {
  return (
    <Suspense fallback={<></>}>
      {children}
    </Suspense>
  )
}

export default router