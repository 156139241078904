import { TLanguageResponseItem } from "api/responseInterfaces/general.response.interface"
import { TLangType } from "./LanguageProvider"

interface IinitialLanguageState {
	language: TLangType
	languages: TLanguageResponseItem[]
	setLanguageAction: (language: TLangType) => void
}

const initialLanguageState: IinitialLanguageState = {
	language: 'es',
	languages: [],
	setLanguageAction: () => { }
}

export default initialLanguageState