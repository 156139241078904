import { IBodyProductSearch, IProductState } from "interfaces/catalog.interface"
import { basePath } from "./utils/config"
import { TUpdateProductodyFetch } from "contexts/catalog/reducer/catalog.actions"
import { IBodyFilterCSVState } from "interfaces/catalogDownload.interface"

export const getCatalog = async (body: IBodyProductSearch | IBodyFilterCSVState, token: string, signal: AbortSignal | undefined): Promise<any> => {
	const params = {
		signal: signal,
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/product/brand/get_product`, params)
	const data = await response.json()
	return { response, data }
}

export const getCatalogApi = async (body: IBodyProductSearch, token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/product/brand/get_product`, params)
	const data = await response.json()
	return { response, data }
}

export const getFiltersAPI = async (brandId: string, countryId: string, languageId: string, token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			brand_id: brandId,
			country_id: countryId,
			language_id: languageId
		})
	}
	const response = await fetch(`${basePath}/filter/get_brand_filter`, params)
	const data = await response.json()
	return { response, data }
}

export const addLikeAPI = async (userId: string, productId: string, token: string): Promise<any> => {
	const params = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			user_id: userId,
			product_id: productId
		}),
	}
	const response = await fetch(`${basePath}/favorite/create_favorite_product`, params)
	const data = await response.json()
	return { response, data }
}

export const removeLikeAPI = async (favoriteId: string, token: string): Promise<any> => {
	const params = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		}
	}
	const response = await fetch(`${basePath}/favorite/delete_favorite_product?favorite_id=${favoriteId}`, params)
	const data = await response.json()
	return { response, data }
}

type TgetProductAPIResponse = {
	response: Response
	data: IProductState
}
export const getProductAPI = async (productId: string, brandId: string, languageId: string, userId: string, token: string): Promise<TgetProductAPIResponse> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/product/product_profile?product_id=${productId}&brand_id=${brandId}&language_id=${languageId}&user_id=${userId}`, params)
	const data = await response.json()
	return { response, data }
}

export const updateProductAPI = async (body: TUpdateProductodyFetch, token: string): Promise<any> => {
	const params = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body)
	}
	const response = await fetch(`${basePath}/product_management/update_product`, params)
	const data = await response.json()
	return { response, data }
}

export const getUrlToSendCSV = async (brandId: string): Promise<any> => {
	const params = {
		method: "GET",
		// headers: {
		// 	"Content-Type": "application/json",
		// },
		// body: JSON.stringify({
		// 	method: "catalog_csv",
		// 	credentials: {
		// 		tenant_id: "",
		// 		client_id: "",
		// 		user_id: "",
		// 		brand_id: brandId
		// 	}
		// }),
	}
	const response = await fetch(`https://dbn4v87jkd.execute-api.eu-west-1.amazonaws.com/Prod/ping`, params)
	const data = await response.json()
	return { response, data }
}

export const uploadFileAPI = async (url: string, formData: any): Promise<any> => {
	const params = {
		method: "PUT",
		body: formData
	}
	const response = await fetch(url, params)
	const data = await response.json()
	return { response, data }
}

export const getUnmatchedImagesAPI = async (brandId: string, querySearch: string[], token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			brand_id: brandId,
			only_null: true,
			search: querySearch,
			reference_id: [],
			color_id: [],
			index: 0,
			limit: 10
		})
	}
	const response = await fetch(`${basePath}/image_management/get_unmatched_images`, params)
	const data = await response.json()
	return { response, data }
}