import { IInitialSuperAdminState } from 'interfaces/superAdmin.interface';
import * as SuperAdminOfSaleType from './superAdmin.types'

export default function superAdminReducer(state: IInitialSuperAdminState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case SuperAdminOfSaleType.SET_CLIENTS:
			const setClients: IInitialSuperAdminState = {
				...state,
				clients: payload
			}
			return setClients

		case SuperAdminOfSaleType.SET_IS_LOADING:
			const setIsLoading: IInitialSuperAdminState = {
				...state,
				isLoading: payload
			}
			return setIsLoading

		default:
			return state
	}
}