import * as NotificationsTypes from './notifications.types'
// import { myBrandsError } from 'utils/errorCodes';
// import { notificationError } from 'views/components/UI/notification';
// import { messageError, messageSuccess, messageWarning } from 'views/components/UI/message';

export async function getNotificationsAction(dispatch: any, translate: any) {
	return dispatch({
		type: NotificationsTypes.SET_NOTIFICATIONS,
	})
}

export async function markAsReadedAction(dispatch: any, type: string, notificationId: string, read: boolean, translate: any) {
	if (type === 'notification') {
		return dispatch({
			type: NotificationsTypes.MARK_AS_READED_NOTIFICATION,
			payload: {
				id: notificationId,
				readed: read
			}
		})
	}
	if (type === 'alert') {
		return dispatch({
			type: NotificationsTypes.MARK_AS_READED_ALERT,
			payload: {
				id: notificationId,
				readed: read
			}
		})
	}
}