export const FAMILY_FILTER = 'family'
export const COLOR_FILTER = 'color'
export const GENDER_FILTER = 'gender'
export const SEASON_FILTER = 'season'
export const DIVISION_FILTER = 'division'
export const SEGMENTATION_FILTER = 'segmentation'
export const PRICE_FILTER = 'price'
export const PRICE_FILTER_PVI = 'pvi'
export const PRICE_FILTER_PVPR = 'pvpr'
export const ITEMS_COUNT_DEFAULT = 30
export const REFERENCE_FILTER = 'reference'
export const TAGS_FILTER = 'tags'
export const TIER_FILTER = 'tier'
export const MATERIAL_FILTER = 'material'