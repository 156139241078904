import { memo, useState } from "react"
import { DrawersMultimediaContext } from "./DrawersMultimediaContext"
import { ChildrenProps } from "types"

const DrawersMultimediaProvider = ({ children }: ChildrenProps) => {
	const [isOpenImagesDrawer, setIsOpenImagesDrawer] = useState(false)
	const [isOpenVideosDrawer, setIsOpenVideosDrawer] = useState(false)

	const setIsOpenImagesDrawerAction = (isOpen: boolean) => {
		setIsOpenImagesDrawer(isOpen)
	}

	const setIsOpenVideosDrawerAction = (isOpen: boolean) => {
		setIsOpenVideosDrawer(isOpen)
	}

	return (
		<DrawersMultimediaContext.Provider value={{
			isOpenImagesDrawer,
			setIsOpenImagesDrawerAction,
			isOpenVideosDrawer,
			setIsOpenVideosDrawerAction
		}}>
			{children}
		</DrawersMultimediaContext.Provider>
	)
}

export default memo(DrawersMultimediaProvider)