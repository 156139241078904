import * as NotificationsTypes from './notifications.types'

export default function notificationsReducer(state: any, action: any) {
	const { type, payload } = action;

	switch (type) {
		case NotificationsTypes.SET_NOTIFICATIONS:
			return {
				...state,
				notifications: state.notifications.sort((a: any, b: any) => a.createdAt > b.createdAt ? -1 : 1),
				alerts: state.alerts.sort((a: any, b: any) => a.createdAt > b.createdAt ? -1 : 1)
			}

		case NotificationsTypes.MARK_AS_READED_NOTIFICATION:
			const notificationsCopy = [...state.notifications]
			const indexNotification = notificationsCopy.findIndex((notification: any) => notification.id === payload.id)
			notificationsCopy[indexNotification].read = payload.readed
			return {
				...state,
				notifications: notificationsCopy.sort((a: any, b: any) => a.createdAt > b.createdAt ? -1 : 1)
			}

		case NotificationsTypes.MARK_AS_READED_ALERT:
			const alertCopy = [...state.alerts]
			const indexAlert = alertCopy.findIndex((alert: any) => alert.id === payload.id)
			alertCopy[indexAlert].read = payload.readed
			return {
				...state,
				alerts: alertCopy.sort((a: any, b: any) => a.createdAt > b.createdAt ? -1 : 1)
			}


		default:
			return state
	}
}