import { notification } from "antd";

export const notificationError = ({ msg, description, errorCode }: { msg: string, description: string, errorCode: string }) => {
  return notification.error({
    message: `${msg}: ${errorCode}`,
    description: description,
    placement: 'bottomRight',
    duration: 0,
    style: { backgroundColor: '#212529' }
  });
}

export const notificationErr = (error: any, translate: any) => {
  return notificationError({
    msg: translate('notification-error_message'),
    description: translate('notification-error_description'),
    errorCode: error
  })
}