import * as apiUser from 'api/user.api';
import * as apiCompany from 'api/company.api';
import * as UserTypes from './user.types'
import { notificationErr } from 'views/components/UI/notification';
import { userCompanyError } from 'utils/errorCodes';
import { messageError, messageLoading, messageSuccess } from 'views/components/UI/message';
import { getAssociationsStatesAPI } from 'api/general.api';
import * as status from 'contexts/clients/constants';
import * as clientKey from 'utils/constants';
import { ISAdminTenantState, ISAdminUserTenantState } from 'interfaces/superAdmin.interface';
import { IAssociationsStates, ICompanyForm, ICompanyState, IDbUserForm, IDbUserState, IInitialUserState, TAssociation, TLanguage, TSocialMedia } from 'interfaces/user.interface';
import { TLanguageResponseItem } from 'api/responseInterfaces/general.response.interface';

let user: any;
export async function loginUserAction(
	dispatch: Function,
	token: string,
	auth0User: any,
	translate: Function,
	logout: Function
) {
	let stateValues: IAssociationsStates = {
		pending: {} as TAssociation,
		connected: {} as TAssociation,
		rejected: {} as TAssociation,
		disconnected: {} as TAssociation,
	}
	try {
		const [userFetch, associationStatesFetch] = await Promise.all([
			apiUser.loginDbUserAPI(token),
			getAssociationsStatesAPI(token)
		])
		if (!associationStatesFetch?.response || associationStatesFetch?.response?.status !== 200 || !associationStatesFetch.data || associationStatesFetch?.data?.length === 0) {
			notificationErr(userCompanyError.login_user_006, translate)
		} else {
			for (const state of associationStatesFetch.data) {
				if (state.state === status.pending) {
					stateValues.pending = state
				}
				if (state.state === status.connected) {
					stateValues.connected = state
				}
				if (state.state === status.rejected) {
					stateValues.rejected = state
				}
				if (state.state === status.disconnected) {
					stateValues.disconnected = state
				}
			}
			dispatch({ type: UserTypes.SET_ASSOCIATIONS_STATES, payload: stateValues })
		}
		await loginExcep(userFetch, logout, translate, dispatch, token, userCompanyError.login_user_001)
	} catch (err) {
		return notificationErr(userCompanyError.login_user_002, translate)
	} finally {
		if (user?.response?.status === 200 && user.data.enabled === false && user.data.id && auth0User.email_verified === true) {
			try {
				const body = {
					filter: { id: user.data.id },
					update: { enabled: true }
				}
				const verifyFetch = await apiUser.verifyEmailAPI(body, token)
				if (verifyFetch.response.status === 200) {
					return dispatch({
						type: UserTypes.VERIFY_EMAIL,
						payload: true
					})
				}
			} catch (error) {
				messageError('Error al verificar el usuario.')
				return notificationErr(userCompanyError.login_user_003, translate)
			}
		} else {
			return dispatch({
				type: UserTypes.VERIFY_EMAIL,
				payload: false
			})
		}
	}
}

export async function updateUserAction(
	dispatch: { (value: any): void; (arg0: { type: string; payload: any; }): any; },
	dbUser: IDbUserState,
	data: IDbUserForm,
	token: string,
	setIsOpenDrawer: (boolean: boolean) => void,
	translate: Function
) {
	const body = { filter: { id: dbUser.id }, update: data }
	// messageLoading('Actualizando usuario...')
	try {
		const fetch = await apiUser.updateDbUserAPI(body, token)

		if (fetch.response.status === 403) {
			messageError('No tienes permisos.')
			return notificationErr(userCompanyError.update_user_002, translate)
		}

		if (fetch.response.status === 200) {
			messageSuccess('Usuario actualizado correctamente.')
			setIsOpenDrawer(false)
			return dispatch({
				type: UserTypes.UPDATE_USER,
				payload: data
			})
		}
	} catch (err) {
		messageError('Error al actualizar el usuario.')
		return notificationErr(userCompanyError.update_user_001, translate)
	}
}

export async function updateCompanyAction(
	dispatch: { (value: any): void; (arg0: { type: string; payload: ICompanyForm; }): any; },
	userState: IInitialUserState,
	companyData: ICompanyForm & { new_social_media?: TSocialMedia[] },
	token: string,
	setIsOpenDrawer: (boolean: boolean) => void,
	translate: Function
) {
	const newSocialMedia = companyData.new_social_media
	delete companyData.new_social_media
	const body = { filter: { id: userState.company.id }, update: companyData }
	try {
		const [companyFetch, socialMediaFetch] = await Promise.all([
			apiCompany.updateTenantDataAPI(body, token),
			apiCompany.addSocialMediaAPI(userState.company.id, newSocialMedia, token)
		])
		if (socialMediaFetch.response.status === 200 && socialMediaFetch.data.length > 0) {
			for (const item of socialMediaFetch.data) {
				const socialMediaItem: TSocialMedia = {
					id: item.id,
					name: item.name,
					url: item.url,
					tenant_id: item.tenant_id,
				}
				dispatch({
					type: UserTypes.ADD_SOCIAL_MEDIA,
					payload: socialMediaItem
				})
			}
		}
		if (companyFetch.response.status === 200) {
			messageSuccess('Datos de la compañia actualizados correctamente.')
			setIsOpenDrawer(false)
			return dispatch({
				type: UserTypes.UPDATE_COMPANY,
				payload: companyData
			})
		}
		if (companyFetch.response.status === 404) {
			messageError('No se ha encontrado al usuario.')
			return notificationErr(userCompanyError.update_company_003, translate)
		}
		messageError('Error al actualizar los datos de empresa.')
		return notificationErr(userCompanyError.update_company_002, translate)
	} catch (err) {
		messageError('Error al actualizar los datos de empresa.')
		return notificationErr(userCompanyError.update_company_001, translate)
	}
}

export async function updateUserAvatarAction(
	dispatch: { (value: any): void; (arg0: { type: string; payload: any; }): any; },
	dbUserState: IDbUserState,
	image: File,
	token: any,
	translate: Function
) {
	messageLoading('Actualizando imagen...')
	try {
		const fetch = await apiUser.updateUserAvatarAPI(dbUserState.id, image, token)
		if (fetch.response.status === 200) {
			messageSuccess('Avatar de usuario actualizado correctamente.')
			dispatch({
				type: UserTypes.UPDATE_USER_AVATAR,
				payload: { url: fetch.result.url }
			})
		}
		return
	} catch (err) {
		messageError('Error al actualizar el avatar de usuario.')
		return notificationErr(userCompanyError.update_user_avatar_001, translate)
	}
}

export async function updateCompanyLogoAction(
	dispatch: { (value: any): void; (arg0: { type: string; payload: any; }): any; },
	tenantId: string,
	image: File,
	token: string,
	translate: Function
) {
	messageLoading('Actualizando imagen...')
	try {
		const fetch = await apiUser.updateCompanyLogoAPI(tenantId, image, token)
		if (fetch.response.status === 200) {
			messageSuccess('Logo de compañia actualizado correctamente.')
			dispatch({
				type: UserTypes.UPDATE_COMPANY_LOGO,
				payload: { url: fetch.result.url }
			})
		}
		return
	} catch (err) {
		messageError('Error al actualizar el logo de la compañia.')
		return notificationErr(userCompanyError.update_company_logo_001, translate)
	}
}

export async function deleteUserAction(dispatch: any, user: any, token: any, translate: any) {
	const body = { filter: { id: user.sub } }
	messageLoading('Eliminando isuario...')
	try {
		const response = await apiUser.deleteAuth0UserAPI(body, token)
		return dispatch({
			type: UserTypes.LOGIN_USER,
			payload: response
		})
	} catch (err) {
		messageError('Error al eliminar el usuario.')
		return notificationErr(userCompanyError.delete_user_001, translate)
	}
}

export async function updateLanguageAction(
	dispatch: Function,
	dbUser: IDbUserState,
	language: TLanguage,
	token: string,
	translate: Function
) {
	if (language.id === dbUser.language.id) return
	const body = {
		filter: { id: dbUser.id },
		update: { i18n_lang_id: language.id }
	}
	try {
		const updateLanguageFetch = await apiUser.updateUserLanguageAPI(body, token)
		if (updateLanguageFetch.response.status === 200) {
			return dispatch({ type: UserTypes.UPDATE_LANGUAGE, payload: language })
		} else {
			return notificationErr(userCompanyError.update_language_002, translate)
		}
	} catch (err) {
		return notificationErr(userCompanyError.update_language_001, translate)
	}
}

export async function updateSocialMediaAction(
	dispatch: Function,
	companyState: ICompanyState,
	socialMedia: TSocialMedia,
	setShowConfirmEditBtn: Function,
	token: string,
	translate: Function
) {
	const { id, url, name } = socialMedia
	try {
		const updateFetch = await apiCompany.updateSocialMediaAPI(companyState.id, id, url, name, token)
		if (updateFetch.data[0] === 1 || updateFetch.data === 1) {
			setShowConfirmEditBtn(false)
			messageSuccess('Red social actualizada correctamente.')
			const findSocialMedia = companyState.social_media.findIndex((el: any) => el.id === id)
			companyState.social_media[findSocialMedia].url = url
			companyState.social_media[findSocialMedia].name = name
			return dispatch({
				type: UserTypes.SET_SOCIAL_MEDIA,
				payload: companyState.social_media
			})
		} else {
			messageError('Error al actualizar la red social.')
			return notificationErr(userCompanyError.updateSocialMedia_002, translate)
		}
	} catch (err) {
		messageError('Error al actualizar la red social.')
		return notificationErr(userCompanyError.updateSocialMedia_001, translate)
	}
}

export async function removeSocialMediaAction(
	dispatch: Function,
	companyState: ICompanyState,
	socialMediaId: string,
	token: string,
	translate: Function
) {
	try {
		const removeFetch = await apiCompany.removeSocialMediaAPI(socialMediaId, token)
		if (removeFetch.data === 1) {
			const filteredCompany = companyState.social_media.filter((el: any) => el.id !== socialMediaId)
			messageSuccess('Red social eliminada correctamente.')
			return dispatch({
				type: UserTypes.SET_SOCIAL_MEDIA,
				payload: filteredCompany
			})
		} else {
			messageError('Error al eliminar la red social.')
			return notificationErr(userCompanyError.removeSocialMedia_002, translate)
		}
	} catch (err) {
		return notificationErr(userCompanyError.removeSocialMedia_001, translate)
	}
}

export async function selectUserAndCompanySuperAdminAction(
	dispatch: Function,
	tenant: ISAdminTenantState,
	user: ISAdminUserTenantState,
	languages: TLanguageResponseItem[],
	translate: Function,
	token: string
) {
	if (!tenant || !user) return
	try {
		const [companyFetch] = await Promise.all([
			apiCompany.getCompanyInfoAPI({tenantId: tenant.id, countryId: tenant.country.id, token}),
		])
		return dispatch({
			type: UserTypes.LOGIN_COMPANY_USER_SUPER_ADMIN,
			payload: {
				user: user,
				company: companyFetch.data,
				languages
			}
		})
	} catch (error) {
		console.log(error);
	} finally {
		dispatch({ type: UserTypes.SET_IS_LOADING, payload: false })
	}
}

export async function selectCompanySuperAdminAction(dispatch: Function, tenant: ISAdminTenantState, translate: Function, token: string) {
	if (!tenant) return
	try {
		const [companyFetch] = await Promise.all([
			apiCompany.getCompanyInfoAPI({tenantId: tenant.id, countryId: tenant.country.id, token}),
		])
		return dispatch({
			type: UserTypes.LOGIN_COMPANY_SUPER_ADMIN,
			payload: {
				company: companyFetch.data
			}
		})
	} catch (err) {
		console.log(err);
	} finally {
		dispatch({ type: UserTypes.SET_IS_LOADING, payload: false })
	}
}

///////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////

const loginExcep = async (userFetch: any, logout: Function, translate: Function, dispatch: Function, token: string, error: any) => {
	if (!userFetch) return

	const getUrlToRedirectId = () => {
		if (process.env.REACT_APP_NODE_ENV === clientKey.clientLocalKey || !process.env.REACT_APP_NODE_ENV) {
			return clientKey.clientLocal
		} else if (process.env.REACT_APP_NODE_ENV === clientKey.clientProdKey) {
			return clientKey.clientProd
		} else if (process.env.REACT_APP_NODE_ENV === clientKey.clientDevKey) {
			return clientKey.clientDev
		} else if (process.env.REACT_APP_NODE_ENV === clientKey.clientStagingKey) {
			return clientKey.clientStaging
		}
	}
	
	if (userFetch?.response?.status === 404 || userFetch.data.info === 'User not found') {
		return logout()
	}

	if (userFetch?.response?.status === 403) {
		return window.location.replace(getUrlToRedirectId() as string)
	}

	if (userFetch?.response?.status === 200) {
		user = userFetch
		if (user.data.role === clientKey.superAdminRole) {
			return dispatch({
				type: UserTypes.LOGIN_USER,
				payload: {
					user: userFetch.data,
				}
			})
		} else {
			try {
				const tenantId = userFetch?.data?.user_tenants[0]?.tenant?.tenant_id
				const [companyFetch] = await Promise.all([
					apiCompany.getCompanyInfoAPI({tenantId: tenantId, countryId: userFetch.data.user_tenants[0].tenant.country_id, token}),
				])
				return dispatch({
					type: UserTypes.LOGIN_USER,
					payload: {
						user: userFetch.data,
						company: companyFetch.data,
					}
				})
			} catch (error) {
				console.log(error)
			} finally {
				dispatch({ type: UserTypes.SET_IS_LOADING, payload: false })
			}
		}
	} else {
		return notificationErr(error, translate)
	}
}