import { IClientsInitialState, IPointOfSaleState } from 'interfaces/clients.interface';
import * as ClientsTypes from './clients.types'

export default function clientsReducer(state: IClientsInitialState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case ClientsTypes.GET_CLIENTS:
			const getClients: IClientsInitialState = {
				...state,
				clients: [...state.clients, payload],
				allClients: [...state.clients, payload],
				pointsOfSale: state.pointsOfSale.concat(payload.clientSalePoint),
				allPointsOfSale: state.pointsOfSale.concat(payload.clientSalePoint),
			}
			return getClients

		case ClientsTypes.SET_CLIENTS:
			const setClients: IClientsInitialState = {
				...state,
				clients: payload,
			}
			return setClients

		case ClientsTypes.SET_ALL_CLIENTS:
			const setAllClients: IClientsInitialState = {
				...state,
				allClients: payload,
			}
			return setAllClients

		case ClientsTypes.SET_POINTS_OF_SALE:
			const setPointsOfSale: IClientsInitialState = {
				...state,
				pointsOfSale: payload,
			}
			return setPointsOfSale

		case ClientsTypes.SET_ALL_POINTS_OF_SALE:
			const setAllPointsOfSale: IClientsInitialState = {
				...state,
				allPointsOfSale: payload,
			}
			return setAllPointsOfSale

		case ClientsTypes.SELECT_CLIENT:
			const selectClient: IClientsInitialState = {
				...state,
				clientSelected: payload
			}
			return selectClient

		case ClientsTypes.SELECT_POINT_OF_SALE:
			const selectPointOfSale: IClientsInitialState = {
				...state,
				pointOfSaleSelected: payload
			}
			return selectPointOfSale

		case ClientsTypes.CREATE_CLIENT:
			const createClient: IClientsInitialState = {
				...state,
				clients: [...state.clients, payload]
			}
			return createClient

		case ClientsTypes.UPDATE_CLIENT:
			const updateClient: IClientsInitialState = {
				...state,
				clientSelected: {
					...state.clientSelected,
					clientCode: payload.clientCode,
					tradeName: payload.name,
					businessName: payload.legal_name,
					cif: payload.cif,
					address: payload.address,
					postal_code: payload.postal_code,
					city: payload.city,
					country: {
						id: payload.country.id,
						name: payload.country.name
					},
					province: payload.province,
					floor: payload.floor,
					door: payload.door,
					lat: payload.lat,
					lng: payload.lng,
					contact_name: payload.contact_name,
					email: payload.email,
					web: payload.web,
					tel_prefix: payload.tel_prefix,
					tel_sufix: payload.tel_sufix,
					youtube: payload.youtube,
					facebook: payload.facebook,
					instagram: payload.instagram,
					linkedin: payload.linkedin,
				}
			}
			return updateClient

		case ClientsTypes.UPGRADE_CLIENT:
			const upgradeClient: IClientsInitialState = {
				...state,
				clientSelected: {
					...state.clientSelected,
					...payload,
				}
			}
			return upgradeClient

		case ClientsTypes.IS_LOADING_UPGRADE_CLIENT:
			const isLoadingUpgradeClient: IClientsInitialState = {
				...state,
				isLoadingUpgradeClientItems: payload
			}
			return isLoadingUpgradeClient

		case ClientsTypes.SET_IS_LOADING_CLIENT:
			const setIsLoadingClient: IClientsInitialState = {
				...state,
				isLoadingClient: payload
			}
			return setIsLoadingClient

		case ClientsTypes.REFRESH_CLIENTS:
			const refreshClients: IClientsInitialState = {
				...state,
				refreshClients: payload
			}
			return refreshClients

		case ClientsTypes.CREATE_POINT_OF_SALE:
			const createPointOfSale: IClientsInitialState = {
				...state,
				clientSelected: {
					...state.clientSelected,
					clientSalePoint: [...state.clientSelected.clientSalePoint, payload.newPointOfSale]
				}
			}
			return createPointOfSale

		case ClientsTypes.UPDATE_POINT_OF_SALE:
			const updatePointOfSale: IClientsInitialState = {
				...state,
				clientSelected: payload,
			}
			return updatePointOfSale

		case ClientsTypes.REMOVE_POINT_OF_SALE:
			const removePointOfSale: IClientsInitialState = {
				...state,
				clientSelected: {
					...state.clientSelected,
					clientSalePoint: state.clientSelected?.clientSalePoint?.filter((point) => point.key !== payload),
					pendingPointsOfSaleAssociations: state.clientSelected.pendingPointsOfSaleAssociations - 1
				},
				pointsOfSale: state.pointsOfSale.filter((point) => point.key !== payload),
				allPointsOfSale: state.allPointsOfSale.filter((point) => point.key !== payload),
				pointOfSaleSelected: {} as IPointOfSaleState,
				clients: state.clients.map((client) => {
					if (client.key === state.clientSelected.key) {
						return {
							...client,
							clientSalePoint: client?.clientSalePoint?.filter((point) => point.key !== payload)
						}
					}
					return client
				})
			}
			return removePointOfSale

		case ClientsTypes.SET_QUERY_CLIENTS_SEARCH:
			const setQueryClientsSearch: IClientsInitialState = {
				...state,
				queryClientsSearch: payload,
			}
			return setQueryClientsSearch

		case ClientsTypes.SET_QUERY_POINTS_OF_SALE_SEARCH:
			const setQueryPointsOfSaleSearch: IClientsInitialState = {
				...state,
				queryPointsOfSaleSearch: payload,
			}
			return setQueryPointsOfSaleSearch

		case ClientsTypes.SET_POINTS_OF_SALE_CATEGORIES:
			const setPointsOfSaleCategories: IClientsInitialState = {
				...state,
				pointsOfSaleCategories: payload,
			}
			return setPointsOfSaleCategories

		case ClientsTypes.UPDATE_ASSOCIATION_INFO:
			const updateAssociationInfo: IClientsInitialState = {
				...state,
				clientSelected: {
					...state.clientSelected,
					associationInfo: {
						...state.clientSelected.associationInfo,
						[payload.field]: payload.value
					}
				}
			}
			return updateAssociationInfo

		case ClientsTypes.SET_EXCHANGES:
			const setExchanges: IClientsInitialState = {
				...state,
				exchanges: payload,
			}
			return setExchanges

		case ClientsTypes.SET_SEGMENTATIONS:
			const setSegmentations: IClientsInitialState = {
				...state,
				segmentations: payload,
			}
			return setSegmentations

		case ClientsTypes.SET_DATA_COLUMNS_WITH_CHANGES:
			const setDataColumnsWithChanges: IClientsInitialState = {
				...state,
				clientSelected: {
					...state.clientSelected,
					dataColumnsWithChanges: {
						...state.clientSelected.dataColumnsWithChanges,
						values: state.clientSelected.dataColumnsWithChanges.values.filter(item => item.keyValue !== payload.keyValue),
					}
				},
				clients: state.clients.map(client => {
					if (client.key === payload.clientId) {
						return {
							...client,
							dataColumnsWithChanges: {
								...state.clientSelected.dataColumnsWithChanges,
								values: state.clientSelected.dataColumnsWithChanges.values.filter(item => item.keyValue !== payload.keyValue),
							}
						}
					}
					return client
				}),
			}
			return setDataColumnsWithChanges

		case ClientsTypes.SET_DATA_COLUMNS:
			const setDataColumns: IClientsInitialState = {
				...state,
				clientSelected: {
					...state.clientSelected,
					dataColumns: payload.dataColumns
				},
			}
			return setDataColumns

		default:
			return state
	}
}