export const countriesTranslation = {
  // Countries
  'google-address-form_country_AF': 'Afghanistan',
  'google-address-form_country_AX': 'Åland Islands',
  'google-address-form_country_AL': 'Albania',
  'google-address-form_country_DZ': 'Algeria',
  'google-address-form_country_AS': 'American Samoa',
  'google-address-form_country_AD': 'Andorra',
  'google-address-form_country_AO': 'Angola',
  'google-address-form_country_AI': 'Anguilla',
  'google-address-form_country_AQ': 'Antarctica',
  'google-address-form_country_AG': 'Antigua and Barbuda',
  'google-address-form_country_AR': 'Argentina',
  'google-address-form_country_AM': 'Armenia',
  'google-address-form_country_AW': 'Aruba',
  'google-address-form_country_AU': 'Australia',
  'google-address-form_country_AT': 'Austria',
  'google-address-form_country_AZ': 'Azerbaijan',
  'google-address-form_country_BS': 'Bahamas',
  'google-address-form_country_BH': 'Bahrain',
  'google-address-form_country_BD': 'Bangladesh',
  'google-address-form_country_BB': 'Barbados',
  'google-address-form_country_BY': 'Belarus',
  'google-address-form_country_BE': 'Belgium',
  'google-address-form_country_BZ': 'Belize',
  'google-address-form_country_BJ': 'Benin',
  'google-address-form_country_BM': 'Bermuda',
  'google-address-form_country_BT': 'Bhutan',
  'google-address-form_country_BO': 'Bolivia',
  'google-address-form_country_BQ': 'Bonaire, Sint Eustatius and Saba',
  'google-address-form_country_BA': 'Bosnia and Herzegovina',
  'google-address-form_country_BW': 'Botswana',
  'google-address-form_country_BV': 'Bouvet Island',
  'google-address-form_country_BR': 'Brazil',
  'google-address-form_country_IO': 'British Indian Ocean Territory',
  'google-address-form_country_BN': 'Brunei Darussalam',
  'google-address-form_country_BG': 'Bulgaria',
  'google-address-form_country_BF': 'Burkina Faso',
  'google-address-form_country_BI': 'Burundi',
  'google-address-form_country_CV': 'Cabo Verde',
  'google-address-form_country_KH': 'Cambodia',
  'google-address-form_country_CM': 'Cameroon',
  'google-address-form_country_CA': 'Canada',
  'google-address-form_country_KY': 'Cayman Islands',
  'google-address-form_country_CF': 'Central African Republic',
  'google-address-form_country_TD': 'Chad',
  'google-address-form_country_CL': 'Chile',
  'google-address-form_country_CN': 'China',
  'google-address-form_country_CX': 'Christmas Island',
  'google-address-form_country_CC': 'Cocos Islands',
  'google-address-form_country_CO': 'Colombia',
  'google-address-form_country_KM': 'Comoros',
  'google-address-form_country_CG': 'Congo (Congo-Brazzaville)',
  'google-address-form_country_CD': 'Congo (Democratic Republic of the)',
  'google-address-form_country_CK': 'Cook Islands',
  'google-address-form_country_CR': 'Costa Rica',
  'google-address-form_country_HR': 'Croatia',
  'google-address-form_country_CU': 'Cuba',
  'google-address-form_country_CW': 'Curaçao',
  'google-address-form_country_CY': 'Cyprus',
  'google-address-form_country_CZ': 'Czechia',
  'google-address-form_country_DK': 'Denmark',
  'google-address-form_country_DJ': 'Djibouti',
  'google-address-form_country_DM': 'Dominica',
  'google-address-form_country_DO': 'Dominican Republic',
  'google-address-form_country_EC': 'Ecuador',
  'google-address-form_country_EG': 'Egypt',
  'google-address-form_country_SV': 'El Salvador',
  'google-address-form_country_GQ': 'Equatorial Guinea',
  'google-address-form_country_ER': 'Eritrea',
  'google-address-form_country_EE': 'Estonia',
  'google-address-form_country_SZ': 'Eswatini',
  'google-address-form_country_ET': 'Ethiopia',
  'google-address-form_country_FK': 'Falkland Islands',
  'google-address-form_country_FO': 'Faroe Islands',
  'google-address-form_country_FJ': 'Fiji',
  'google-address-form_country_FI': 'Finland',
  'google-address-form_country_FR': 'France',
  'google-address-form_country_GF': 'French Guiana',
  'google-address-form_country_PF': 'French Polynesia',
  'google-address-form_country_TF': 'French Southern Territories',
  'google-address-form_country_GA': 'Gabon',
  'google-address-form_country_GM': 'Gambia',
  'google-address-form_country_GE': 'Georgia',
  'google-address-form_country_DE': 'Germany',
  'google-address-form_country_GH': 'Ghana',
  'google-address-form_country_GI': 'Gibraltar',
  'google-address-form_country_GR': 'Greece',
  'google-address-form_country_GL': 'Greenland',
  'google-address-form_country_GD': 'Grenada',
  'google-address-form_country_GP': 'Guadeloupe',
  'google-address-form_country_GU': 'Guam',
  'google-address-form_country_GT': 'Guatemala',
  'google-address-form_country_GG': 'Guernsey',
  'google-address-form_country_GN': 'Guinea',
  'google-address-form_country_GW': 'Guinea-Bissau',
  'google-address-form_country_GY': 'Guyana',
  'google-address-form_country_HT': 'Haiti',
  'google-address-form_country_HM': 'Heard Island and McDonald Islands',
  'google-address-form_country_VA': 'Holy See',
  'google-address-form_country_HN': 'Honduras',
  'google-address-form_country_HK': 'Hong Kong',
  'google-address-form_country_HU': 'Hungary',
  'google-address-form_country_IS': 'Iceland',
  'google-address-form_country_IN': 'India',
  'google-address-form_country_ID': 'Indonesia',
  'google-address-form_country_IR': 'Iran',
  'google-address-form_country_IQ': 'Iraq',
  'google-address-form_country_IE': 'Ireland',
  'google-address-form_country_IM': 'Isle of Man',
  'google-address-form_country_IL': 'Israel',
  'google-address-form_country_IT': 'Italy',
  'google-address-form_country_JM': 'Jamaica',
  'google-address-form_country_JP': 'Japan',
  'google-address-form_country_JE': 'Jersey',
  'google-address-form_country_JO': 'Jordan',
  'google-address-form_country_KZ': 'Kazakhstan',
  'google-address-form_country_KE': 'Kenya',
  'google-address-form_country_KI': 'Kiribati',
  'google-address-form_country_KP': 'North Korea',
  'google-address-form_country_KR': 'South Korea',
  'google-address-form_country_KW': 'Kuwait',
  'google-address-form_country_KG': 'Kyrgyzstan',
  'google-address-form_country_LA': 'Laos',
  'google-address-form_country_LV': 'Latvia',
  'google-address-form_country_LB': 'Lebanon',
  'google-address-form_country_LS': 'Lesotho',
  'google-address-form_country_LR': 'Liberia',
  'google-address-form_country_LY': 'Libya',
  'google-address-form_country_LI': 'Liechtenstein',
  'google-address-form_country_LT': 'Lithuania',
  'google-address-form_country_LU': 'Luxembourg',
  'google-address-form_country_MO': 'Macao',
  'google-address-form_country_MG': 'Madagascar',
  'google-address-form_country_MW': 'Malawi',
  'google-address-form_country_MY': 'Malaysia',
  'google-address-form_country_MV': 'Maldives',
  'google-address-form_country_ML': 'Mali',
  'google-address-form_country_MT': 'Malta',
  'google-address-form_country_MH': 'Marshall Islands',
  'google-address-form_country_MQ': 'Martinique',
  'google-address-form_country_MR': 'Mauritania',
  'google-address-form_country_MU': 'Mauritius',
  'google-address-form_country_YT': 'Mayotte',
  'google-address-form_country_MX': 'Mexico',
  'google-address-form_country_FM': 'Micronesia',
  'google-address-form_country_MD': 'Moldova',
  'google-address-form_country_MC': 'Monaco',
  'google-address-form_country_MN': 'Mongolia',
  'google-address-form_country_ME': 'Montenegro',
  'google-address-form_country_MS': 'Montserrat',
  'google-address-form_country_MA': 'Morocco',
  'google-address-form_country_MZ': 'Mozambique',
  'google-address-form_country_MM': 'Myanmar',
  'google-address-form_country_NA': 'Namibia',
  'google-address-form_country_NR': 'Nauru',
  'google-address-form_country_NP': 'Nepal',
  'google-address-form_country_NL': 'Netherlands',
  'google-address-form_country_NC': 'New Caledonia',
  'google-address-form_country_NZ': 'New Zealand',
  'google-address-form_country_NI': 'Nicaragua',
  'google-address-form_country_NE': 'Niger',
  'google-address-form_country_NG': 'Nigeria',
  'google-address-form_country_NU': 'Niue',
  'google-address-form_country_NF': 'Norfolk Island',
  'google-address-form_country_MK': 'North Macedonia',
  'google-address-form_country_MP': 'Northern Mariana Islands',
  'google-address-form_country_NO': 'Norway',
  'google-address-form_country_OM': 'Oman',
  'google-address-form_country_PK': 'Pakistan',
  'google-address-form_country_PW': 'Palau',
  'google-address-form_country_PS': 'Palestine',
  'google-address-form_country_PA': 'Panama',
  'google-address-form_country_PG': 'Papua New Guinea',
  'google-address-form_country_PY': 'Paraguay',
  'google-address-form_country_PE': 'Peru',
  'google-address-form_country_PH': 'Philippines',
  'google-address-form_country_PN': 'Pitcairn Islands',
  'google-address-form_country_PL': 'Poland',
  'google-address-form_country_PT': 'Portugal',
  'google-address-form_country_PR': 'Puerto Rico',
  'google-address-form_country_QA': 'Qatar',
  'google-address-form_country_RE': 'Reunion',
  'google-address-form_country_RO': 'Romania',
  'google-address-form_country_RU': 'Russia',
  'google-address-form_country_RW': 'Rwanda',
  'google-address-form_country_BL': 'Saint Barthélemy',
  'google-address-form_country_SH': 'Saint Helena',
  'google-address-form_country_KN': 'Saint Kitts and Nevis',
  'google-address-form_country_LC': 'Saint Lucia',
  'google-address-form_country_MF': 'Saint Martin',
  'google-address-form_country_PM': 'Saint Pierre and Miquelon',
  'google-address-form_country_VC': 'Saint Vincent and the Grenadines',
  'google-address-form_country_WS': 'Samoa',
  'google-address-form_country_SM': 'San Marino',
  'google-address-form_country_ST': 'Sao Tome and Principe',
  'google-address-form_country_SA': 'Saudi Arabia',
  'google-address-form_country_SN': 'Senegal',
  'google-address-form_country_RS': 'Serbia',
  'google-address-form_country_SC': 'Seychelles',
  'google-address-form_country_SL': 'Sierra Leone',
  'google-address-form_country_SG': 'Singapore',
  'google-address-form_country_SX': 'Sint Maarten',
  'google-address-form_country_SK': 'Slovakia',
  'google-address-form_country_SI': 'Slovenia',
  'google-address-form_country_SB': 'Solomon Islands',
  'google-address-form_country_SO': 'Somalia',
  'google-address-form_country_ZA': 'South Africa',
  'google-address-form_country_GS': 'South Georgia and the South Sandwich Islands',
  'google-address-form_country_SS': 'South Sudan',
  'google-address-form_country_ES': 'Spain',
  'google-address-form_country_LK': 'Sri Lanka',
  'google-address-form_country_SD': 'Sudan',
  'google-address-form_country_SR': 'Suriname',
  'google-address-form_country_SJ': 'Svalbard and Jan Mayen',
  'google-address-form_country_SE': 'Sweden',
  'google-address-form_country_CH': 'Switzerland',
  'google-address-form_country_SY': 'Syria',
  'google-address-form_country_TW': 'Taiwan',
  'google-address-form_country_TJ': 'Tajikistan',
  'google-address-form_country_TZ': 'Tanzania',
  'google-address-form_country_TH': 'Thailand',
  'google-address-form_country_TL': 'Timor-Leste',
  'google-address-form_country_TG': 'Togo',
  'google-address-form_country_TK': 'Tokelau',
  'google-address-form_country_TO': 'Tonga',
  'google-address-form_country_TT': 'Trinidad and Tobago',
  'google-address-form_country_TN': 'Tunisia',
  'google-address-form_country_TR': 'Turkey',
  'google-address-form_country_TM': 'Turkmenistan',
  'google-address-form_country_TC': 'Turks and Caicos Islands',
  'google-address-form_country_TV': 'Tuvalu',
  'google-address-form_country_UG': 'Uganda',
  'google-address-form_country_UA': 'Ukraine',
  'google-address-form_country_AE': 'United Arab Emirates',
  'google-address-form_country_GB': 'United Kingdom',
  'google-address-form_country_US': 'United States',
  'google-address-form_country_UY': 'Uruguay',
  'google-address-form_country_UZ': 'Uzbekistan',
  'google-address-form_country_VU': 'Vanuatu',
  'google-address-form_country_VE': 'Venezuela',
  'google-address-form_country_VN': 'Vietnam',
  'google-address-form_country_EH': 'Western Sahara',
  'google-address-form_country_YE': 'Yemen',
  'google-address-form_country_ZM': 'Zambia',
  'google-address-form_country_ZW': 'Zimbabwe',
}