import { ISAdminTenantState, ISAdminUserTenantState } from "interfaces/superAdmin.interface"
import { ICompanyForm, ICompanyState, IDbUserForm, IDbUserState, IInitialUserState } from "interfaces/user.interface"

const initialUserState: IInitialUserState = {
	dbUser: {} as IDbUserState,
	company: {} as ICompanyState,
	isLoading: true,
	login: () => { },
	updateUser: (user: IDbUserForm, setIsOpenDrawer: () => void) => { },
	updateUserAvatar: (image: File) => { },
	updateCompanyLogo: (image: File) => { },
	updateCompanyData: (companyData: ICompanyForm, setIsOpenDrawer: () => void) => { },
	deleteUser: (user: any) => { },
	updateLanguage: (language: any) => { },
	updateSocialMedia: (socialMedia: any, company: any, setShowConfirmEditBtn: any) => { },
	removeSocialMedia: (id: string, company?: any) => { },
	selectUserAndCompanySuperAdmin: (client: ISAdminTenantState, user: ISAdminUserTenantState) => { },
	selectCompanySuperAdmin: (client: ISAdminTenantState) => { }
}

export default initialUserState