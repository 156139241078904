import * as api from 'api/brandUsers.api';
import * as BrandUsersTypes from './brandUsers.types'
import { brandUsersError } from 'utils/errorCodes';
import { notificationErr, notificationError } from 'views/components/UI/notification';
import { messageError, messageSuccess, messageWarning } from 'views/components/UI/message';
import { IBrandUser, IInitialBrandUsersState } from 'interfaces/brandUsers.interface';
import { superAdminRole } from 'utils/constants';

export async function getBrandUsersAction(dispatch: any, tenantId: string, userRole: string, token: string, translate: any) {
	try {
		const brandusersFetch = await api.getBrandUsersAPI(tenantId, token)
		if (brandusersFetch.response.status === 200) {
			const orderedBrandUsers: any[] = []
			brandusersFetch.data.forEach((item: IBrandUser & {id: string, lastLoging: string}) => {
				if (userRole !== superAdminRole && item.username.split('@')[1] === 'hub4retail.com') return
				const obj: IBrandUser = {
					key: item.id,
					tenant_id: tenantId,
					auth_id: item.auth_id,
					username: item.username,
					name: item.name,
					surname: item.surname,
					tel_prefix: item.tel_prefix,
					tel_sufix: item.tel_sufix,
					profile_image: item.profile_image,
					language: item.language,
					role: item.role,
					sector: item.sector,
					signed: item.signed,
					first_login: item.first_login,
					last_login: item.lastLoging,
					enabled: item.enabled,
					createdAt: item.createdAt,
					updatedAt: item.updatedAt
				}
				orderedBrandUsers.push(obj)
			});
			return dispatch({
				type: BrandUsersTypes.GET_BRAND_USERS,
				payload: orderedBrandUsers
			})
		}
	} catch (err) {
		messageError('Error al obtener los usuarios.')
		notificationError({
			msg: translate('notification-error_message'),
			description: translate('notification-error_description'),
			errorCode: brandUsersError.get_brand_users_001
		})
	}
}

export async function selectBrandUserAction(dispatch: any, user: any, translate: any) {
	try {
		return dispatch({
			type: BrandUsersTypes.SELECT_BRAND_USER,
			payload: user
		})
	} catch (err) {
		messageError('Error al seleccionar el usuario.')
		notificationError({
			msg: translate('notification-error_message'),
			description: translate('notification-error_description'),
			errorCode: brandUsersError.select_brand_user_001
		})
	}
}

export async function createBrandUserAction(dispatch: any, tenantId: string, newBrandUser: any, languageId: string, token: string, setIsOpenModal: (isOpen: boolean) => void, translate: any, addUserClient: any) {
	dispatch({ type: BrandUsersTypes.SET_IS_LOADING_ACTION, payload: true })
	try {
		const body = {
			user_data: {
				email: newBrandUser.email,
				name: newBrandUser.name,
				nickname: newBrandUser.surname,
				verify_email: true,
				email_verified: false,
				blocked: false
			},
			user_metadata: {
				tenant_id: tenantId,
				telephone_sufix: "",
				telephone_prefix: "",
				language: languageId,

				sector: newBrandUser.sector
			},
			role: newBrandUser.role
		}
		const createFetch = await api.createBrandUserAPI(body, token)
		if (createFetch.response.status === 409) {
			return messageWarning('El usuario ya existe.')
		}
		const { id, username, name, surname, tel_prefix, tel_sufix, profile_image, i18n_lang_id, role, sector, signed, first_login, lastLoging, enabled, createdAt, updatedAt } = createFetch.data.database.db_user
		const { user_id } = createFetch.data.auth0
		if (createFetch.response.status === 201 || createFetch.response.status === 200) {
			const userCreated: IBrandUser = {
				key: id,
				auth_id: user_id,
				tenant_id: tenantId,
				username: username,
				name: name,
				surname: surname,
				tel_prefix: tel_prefix,
				tel_sufix: tel_sufix,
				profile_image: profile_image,
				language: i18n_lang_id,
				role: role,
				sector: sector,
				signed: signed,
				first_login: first_login,
				last_login: lastLoging,
				enabled: enabled,
				createdAt: createdAt,
				updatedAt: updatedAt
			}
			await addUserClient(tenantId, userCreated)
			dispatch({
				type: BrandUsersTypes.CREATE_BRAND_USER,
				payload: userCreated
			})
			messageSuccess('Usuario creado correctamente.')
			return setIsOpenModal(false)
		} else {
			messageError('Error al crear el usuario.')
			notificationError({
				msg: translate('notification-error_message'),
				description: translate('notification-error_description'),
				errorCode: brandUsersError.create_brand_user_002
			})
		}
	} catch (err) {
		messageError('Error al crear el usuario.')
		notificationError({
			msg: translate('notification-error_message'),
			description: translate('notification-error_description'),
			errorCode: brandUsersError.create_brand_user_001
		})
	} finally {
		dispatch({ type: BrandUsersTypes.SET_IS_LOADING_ACTION, payload: false })
	}
}

export async function updateBrandUserAction(dispatch: any, initialState: IInitialBrandUsersState, user: any, userId: string, updateRole: boolean, token: string, setIsOpenDrawer: (isOpen: boolean) => void, translate: any) {
	dispatch({ type: BrandUsersTypes.SET_IS_LOADING_ACTION, payload: true })
	if (updateRole) {
		const findUser = initialState.brandUsers.find((item: any) => item.key === userId)
		if (findUser) {
			if (findUser.role !== user.role) {
				try {
					// Update role
					const updateRoleFetch = await api.updateRoleAPI(userId, user.role, findUser.role, token)
					if (updateRoleFetch.response.status !== 200) {
						messageError('Error al actualizar el rol del usuario.')
						notificationErr(brandUsersError.update_brand_user_003, translate)
					}
				} catch (err) {
					messageError('Error al actualizar el rol del usuario.')
					notificationErr(brandUsersError.update_brand_user_004, translate)
				}
			}
		} else {
			messageError('Error al actualizar el rol del usuario.')
			notificationErr(brandUsersError.update_brand_user_005, translate)
		}
	}
	try {
		const body = {
			filter: { id: userId },
			update: {
				name: user.name,
				surname: user.surname,
				sector: user.sector,
			}
		}
		const updateFetch = await api.updateMainContactAPI(body, token)
		if (updateFetch.response.status === 200) {
			const findBrandUser = initialState.brandUsers.findIndex((item: any) => item.key === userId)
			const newBrandUser = [...initialState.brandUsers]
			newBrandUser[findBrandUser].name = user.name
			newBrandUser[findBrandUser].surname = user.surname
			newBrandUser[findBrandUser].sector = user.sector
			updateRole && (newBrandUser[findBrandUser].role = user.role)
			dispatch({
				type: BrandUsersTypes.UPDATE_BRAND_USER,
				payload: newBrandUser
			})
			messageSuccess('Usuario actualizado correctamente.')
			return setIsOpenDrawer(false)
		} else {
			messageError('Error al actualizar el usuario.')
			notificationErr(brandUsersError.update_brand_user_002, translate)
		}
	} catch (err) {
		messageError('Error al actualizar el usuario.')
		notificationErr(brandUsersError.update_brand_user_001, translate)
	} finally {
		dispatch({ type: BrandUsersTypes.SET_IS_LOADING_ACTION, payload: false })
	}
}

export async function deleteBrandUserAction(dispatch: any, userId: string, token: string, translate: any) {
	try {
		const updateFetch = await api.deleteBrandUserAPI(userId, token)
		if (updateFetch.response.status === 200) {
			dispatch({
				type: BrandUsersTypes.DELETE_BRAND_USER,
				payload: userId
			})
			messageSuccess('Usuario eliminado correctamente.')
		} else {
			messageError('Error al elimminar el usuario.')
			// notificationError({
			// 	msg: translate('notification-error_message'),
			// 	description: translate('notification-error_description'),
			// 	errorCode: brandUsersError.update_brand_user_002
			// })
		}
	} catch (err) {
		messageError('Error al eliminar el usuario.')
		// notificationError({
		// 	msg: translate('notification-error_message'),
		// 	description: translate('notification-error_description'),
		// 	errorCode: brandUsersError.update_brand_user_001
		// })
	}
}