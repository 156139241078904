import { basePath } from "./utils/config"

export const getMainContactsAPI = async (tenantId: string, token: string): Promise<any> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/brand_profile/get_brand_contacts?tenant_id=${tenantId}`, params)
	const data = await response.json()
	return data
}

export const createMainContactAPI = async (tenantId: string, contact: any, token: string): Promise<any> => {
	const params = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(contact),
	}
	const response = await fetch(`${basePath}/brand_profile/create_brand_contact?tenant_id=${tenantId}`, params)
	const data = await response.json()
	return { response, data }
}

export const updateMainContactAPI = async (newContact: any, token: string): Promise<any> => {
	const params = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(newContact),
	}
	const response = await fetch(`${basePath}/brand_profile/update_brand_contact`, params)
	const data = await response.json()
	return data
}

export const removeMainContactAPI = async (contactId: string, token: string): Promise<any> => {
	const params = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		}
	}
	const response = await fetch(`${basePath}/brand_profile/delete_brand_contact?contact_id=${contactId}`, params)
	const data = await response.json()
	return data
}