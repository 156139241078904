/* eslint-disable eqeqeq */
import { initialProductsQuantity, showMoreProducts } from 'utils/constants'
import * as CatalogTypes from './catalog.types'
import * as catalogAPI from 'api/catalog.api'
import { catalogError } from 'utils/errorCodes'
import { notificationErr } from 'views/components/UI/notification'
import { IBodyProductSearch, ICatalogItemsState, ICatalogState, IFavorite, IProductState, TProductInfoFieldNameTypes, TProductInfoTypes, TQuerySearchArray, TSortBy } from 'interfaces/catalog.interface'
import { COLOR_FILTER, DIVISION_FILTER, FAMILY_FILTER, GENDER_FILTER, SEASON_FILTER, SEGMENTATION_FILTER } from 'views/pages/main/catalog/catalogPage/components/catalogFilters/drawerCatalogFilters/filtersConstants'
import { getProductAttributesAPI } from 'api/general.api'

// Catalog
export async function getCatalogAction(
	dispatch: any,
	catalogItems: ICatalogItemsState,
	bodyProductSearch: IBodyProductSearch,
	translate: any,
	signal: AbortSignal | undefined,
	token: string,
) {
	dispatch({ type: CatalogTypes.SET_IS_LOADING, payload: true })
	try {
		const copyOfBodyProductSearch: IBodyProductSearch = JSON.parse(JSON.stringify(bodyProductSearch))
		copyOfBodyProductSearch.options.index = 0
		dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH, payload: copyOfBodyProductSearch })
		dispatch({ type: CatalogTypes.SET_BODY_PRODUCT_SEARCH_TEMP, payload: copyOfBodyProductSearch })
		const products = await getAndOrderImagesCatalog(dispatch, catalogItems, copyOfBodyProductSearch, signal, token)
		if (products) {
			return dispatch({
				type: CatalogTypes.GET_CATALOG,
				payload: products
			})
		}
	} catch (err) {
		// console.log(err);
		// return notificationErr(catalogError.get_catalog_001, translate)
	} finally {
		dispatch({ type: CatalogTypes.SET_IS_LOADING, payload: false })
	}
}

export async function addMoreProductsToCatalogAction(
	dispatch: any,
	catalogItems: ICatalogItemsState,
	bodyProductSearch: IBodyProductSearch,
	translate: any,
	token: string
) {
	const copyOfBodyProductSearch: IBodyProductSearch = JSON.parse(JSON.stringify(bodyProductSearch))
	if (copyOfBodyProductSearch.options.index === 0) {
		copyOfBodyProductSearch.options.index = 10
	}
	dispatch({ type: CatalogTypes.SET_IS_LOADING_MORE_PRODUCTS, payload: true })
	try {
		const products = await getAndOrderImagesCatalog(dispatch, catalogItems, copyOfBodyProductSearch, undefined, token, true)
		if (products) {
			dispatch({ type: CatalogTypes.INCREMENT_PAGINATION_INDEX, payload: copyOfBodyProductSearch.options.index + products.data.length })
			return dispatch({
				type: CatalogTypes.ADD_MORE_TO_CATALOG,
				payload: products
			})
		}
	} catch (err) {
		// return notificationErr(catalogError.add_more_products_to_catalog_001, translate)
	} finally {
		dispatch({ type: CatalogTypes.SET_IS_LOADING_MORE_PRODUCTS, payload: false })
	}
}

export async function addLikeAction(dispatch: any, catalogstate: ICatalogState, userId: string, productId: string, setHaveLike: any, token: string) {
	setHaveLike(true)
	try {
		const copyOfCatalogState: ICatalogState = JSON.parse(JSON.stringify(catalogstate))
		const likeFetch = await catalogAPI.addLikeAPI(userId, productId, token)
		if (likeFetch.response.status === 200) {
			const findProduct = copyOfCatalogState.catalogItems.data.findIndex((item) => item.id === productId)
			const objectToPush = {
				favorite_id: likeFetch.data.id,
				user_id: userId,
			}
			copyOfCatalogState.catalogItems.data[findProduct].favorites.push(objectToPush)
			setHaveLike(true)
			return dispatch({
				type: CatalogTypes.GET_CATALOG,
				payload: copyOfCatalogState.catalogItems
			})
		} else {
			setHaveLike(false)
		}
	} catch (err) {
		setHaveLike(false)
		console.log(err);
	}
}

export async function removeLikeAction(dispatch: any, catalogstate: ICatalogState, productId: string, favorites: any, userId: string, setHaveLike: any, token: string) {
	for (const favorite of favorites) {
		if (favorite.user_id === userId) {
			setHaveLike(false)
			try {
				const copyOfCatalogState: ICatalogState = JSON.parse(JSON.stringify(catalogstate))
				const likeFetch = await catalogAPI.removeLikeAPI(favorite.favorite_id, token)
				if (likeFetch.response.status === 200) {
					const findProduct = copyOfCatalogState.catalogItems.data.findIndex((item) => item.id === productId)
					const filteredFavorites = copyOfCatalogState.catalogItems.data[findProduct].favorites.filter((item) => item.favorite_id !== favorite.favorite_id)
					copyOfCatalogState.catalogItems.data[findProduct].favorites = filteredFavorites
					return dispatch({
						type: CatalogTypes.GET_CATALOG,
						payload: copyOfCatalogState.catalogItems
					})
				} else {
					setHaveLike(true)
				}
			} catch (err) {
				setHaveLike(true)
				console.log(err);
			}
		}
	}
}

// Filters
export async function filterAction(dispatch: any, setOpenDrawer: any, catalogState: ICatalogState, translate: any, signal: AbortSignal | undefined, token: string) {
	setOpenDrawer(false)
	dispatch({ type: CatalogTypes.SET_IS_LOADING, payload: true })
	dispatch({ type: CatalogTypes.INCREMENT_PAGINATION_INDEX, payload: 0 })
	const copyOfCatalogState: ICatalogState = JSON.parse(JSON.stringify(catalogState))
	copyOfCatalogState.bodyProductSearch.options.index = 0
	copyOfCatalogState.bodyProductSearch = copyOfCatalogState.bodyProductSearchTemp
	try {
		await getCatalogAction(dispatch, copyOfCatalogState.catalogItems, copyOfCatalogState.bodyProductSearch, translate, signal, token)
	} catch (err) {
		return notificationErr(catalogError.filter_catalog_001, translate)
	} finally {
		dispatch({ type: CatalogTypes.SET_IS_LOADING, payload: false })
	}
}

export async function addBodyFiltersAction(dispatch: any, field: string, value: string) {
	return dispatch({
		type: CatalogTypes.ADD_BODY_FILTERS,
		payload: {
			field,
			value,
		}
	})
}

export async function removeBodyFiltersAction(dispatch: any, field: string, value: string) {
	return dispatch({
		type: CatalogTypes.REMOVE_BODY_FILTERS,
		payload: {
			field,
			value,
		}
	})
}

export async function resetBodyFiltersAction(dispatch: any, catalogItems: ICatalogItemsState, bodyProductSearch: IBodyProductSearch, setOpenDrawer: any, translate: any, signal: AbortSignal | undefined, token: string) {
	setOpenDrawer && setOpenDrawer(false)
	const copyOfBodyProductSearch: IBodyProductSearch = JSON.parse(JSON.stringify(bodyProductSearch))
	copyOfBodyProductSearch.filter.color = []
	copyOfBodyProductSearch.filter.season = []
	copyOfBodyProductSearch.filter.segmentation = []
	copyOfBodyProductSearch.filter.division = []
	copyOfBodyProductSearch.filter.family = []
	copyOfBodyProductSearch.filter.gender = []
	copyOfBodyProductSearch.filter.pvi = []
	copyOfBodyProductSearch.filter.pvpr = []
	copyOfBodyProductSearch.filter.tags = []
	copyOfBodyProductSearch.filter.tiers = []
	copyOfBodyProductSearch.filter.reference = []
	copyOfBodyProductSearch.options.favorite = ""
	copyOfBodyProductSearch.options.order = []
	copyOfBodyProductSearch.options.limit = initialProductsQuantity
	copyOfBodyProductSearch.options.index = 0
	copyOfBodyProductSearch.options.with_images = null
	copyOfBodyProductSearch.options.search = []
	copyOfBodyProductSearch.options.order = []
	copyOfBodyProductSearch.options.group = []
	getCatalogAction(dispatch, catalogItems, copyOfBodyProductSearch, translate, signal, token)
	dispatch({ type: CatalogTypes.SET_THERE_ARE_MORE_PRODUCTS, payload: true })
	dispatch({ type: CatalogTypes.SET_IS_EMPTY_PRODUCTS, payload: false })
	return dispatch({ type: CatalogTypes.RESET_BODY_FILTERS })
}

export async function setPriceSlidersAction(dispatch: any, field: string, value: string) {
	return dispatch({
		type: CatalogTypes.SET_PRICE_SLIDERS,
		payload: {
			field,
			value,
		}
	})
}

export async function setFavoritesAction(dispatch: any, userId: string) {
	dispatch({ type: CatalogTypes.INCREMENT_PAGINATION_INDEX, payload: 0 })
	return dispatch({ type: CatalogTypes.SET_FAVORITES, payload: userId })
}

export async function resetPriceSliderAction(dispatch: any, type: string, catalogItems: ICatalogItemsState, bodyProductSearch: IBodyProductSearch, translate: any, signal: AbortSignal | undefined, token: string) {
	dispatch({ type: CatalogTypes.RESET_PRICE_SLIDER, payload: { type: type } })
	const copyOfBodyProductSearch: IBodyProductSearch = JSON.parse(JSON.stringify(bodyProductSearch))
	copyOfBodyProductSearch.filter[type as keyof typeof copyOfBodyProductSearch.filter] = []
	getCatalogAction(dispatch, catalogItems, copyOfBodyProductSearch, translate, signal, token)
}

// Product
export async function getProductAction(dispatch: any, productId: string, brandId: string, languageId: string, userId: string, token: any) {
	dispatch({ type: CatalogTypes.SET_IS_LOADING_PRODUCT, payload: true })
	try {
		const getProductFetch = await catalogAPI.getProductAPI(productId, brandId, languageId, userId, token)
		if (getProductFetch.response.status !== 200) return
		const {
			product_id,
			product_description,
			ranking,
			brand_id,
			brand_name,
			brand_logo,
			color,
			color_id,
			color_code,
			reference_id,
			reference_code,
			material,
			tags,
			images,
			ean_size,
			favorite,
			season,
			segmentation,
			family,
			division,
			gender,
			sizes,
			prices,
			other_colors,
		} = getProductFetch.data

		// Sort by las two digits before the image url extension
		const sortedUrls = images?.sort((a: any, b: any) => {
			const matchA = a.url.match(/(\d{2})\.\w+$/);
			const matchB = b.url.match(/(\d{2})\.\w+$/);

			if (!matchA) return 1;
			if (!matchB) return -1;

			const numA = parseInt(matchA[1], 10);
			const numB = parseInt(matchB[1], 10);

			// Priorizar la imagen que termina en 1
			if (numA % 10 === 1 && numB % 10 !== 1) return -1;
			if (numB % 10 === 1 && numA % 10 !== 1) return 1;

			// Si ambos terminan en 1, ordenar de menor a mayor
			if (numA % 10 === 1 && numB % 10 === 1) return numA - numB;

			return numA - numB;
		});

		if (getProductFetch.response.status === 200) {
			const productState: IProductState = {
				product_id: product_id,
				product_description: product_description,
				ranking: ranking,
				brand_id: brand_id,
				brand_name: brand_name,
				brand_logo: brand_logo,
				color_id: color_id,
				color_code: color_code,
				reference_id: reference_id,
				reference_code: reference_code,
				material: material,
				tags: tags,
				images: sortedUrls ?? [],
				ean_size: ean_size.sort((a, b) => ((sizeOrder as any)[a.size] || Infinity) - ((sizeOrder as any)[b.size] || Infinity)),
				favorite: favorite,
				season: season,
				segmentation: segmentation,
				family: family,
				division: division,
				gender: gender,
				color: color,
				sizes: sizes.sort((a, b) => ((sizeOrder as any)[a] || Infinity) - ((sizeOrder as any)[b] || Infinity)),
				prices: prices.sort((a, b) => ((sizeOrder as any)[a.size] || Infinity) - ((sizeOrder as any)[b.size] || Infinity)),
				other_colors: other_colors?.map((item: any) => {
					return {
						id: item.id,
						color: item.color_name,
						colorRef: item.color,
						image: item.url,
					}
				})
			}
			return dispatch({
				type: CatalogTypes.GET_PRODUCT,
				payload: productState
			})
		}
	} catch (err) {
		console.log('error', err);
	} finally {
		dispatch({ type: CatalogTypes.SET_IS_LOADING_PRODUCT, payload: false })
	}
}

export const sizeOrder = {
	'XXS': 0,
	'XS': 1,
	'S': 2,
	'M': 3,
	'L': 4,
	'XL': 5,
	'XXL': 6,
	'3XL': 7,
	'4XL': 8,
	//***** */
	'0': 16,
	'1': 17,
	'1.5': 18,
	'2': 19,
	'2.5': 20,
	'3': 21,
	'3.5': 22,
	'4': 23,
	'4.5': 24,
	'5': 25,
	'5.5': 26,
	'6': 27,
	'6.5': 28,
	'7': 29,
	'7.5': 30,
	'8': 31,
	'8.5': 32,
	'9': 33,
	'9.5': 34,
	'10': 35,
	'10.5': 36,
	'11': 37,
	'11.5': 38,
	'12': 39,
	'12.5': 40,
	'13': 41,
	'13.5': 42,
	'14': 43,
	'14.5': 44,
	'15': 45,
	//***** */
	'24': 53,
	'25': 53.5,
	'26': 54,
	'27': 54.5,
	'28': 55,
	'29': 55.5,
	'30': 56,
	'31': 56.5,
	'32': 57,
	'33': 57.5,
	'34': 58,
	'35': 58.5,
	'36': 59,
	'37': 59.5,
	'38': 60,
	'39': 60.5,
	'40': 61,
	'41': 61.5,
	'42': 62,
	'43': 62.5,
	'44': 63,
	'45': 63.5,
	'46': 64,
	'47': 64.5,
	'48': 65,
	'49': 65.5,
	'50': 66,
	'51': 66.5,
	'52': 67,
	'53': 67.5,
	'54': 68,
	'55': 68.5,
	'56': 69,
	'57': 69.5,
	'58': 70,
	'59': 70.5,
	'60': 71,
	//***** */
	'80': 82,
	'90': 83,
	'100': 84,
	'110': 85,
	'120': 86,
	'130': 87,
	'140': 88,
	'150': 89,
	//***** */
	'6M': 100,
	'12M': 101,
	//***** */
	'1YR': 109,
	'2YR': 110,
	'3YR': 111,
	'4YR': 112,
	'5YR': 113,
	'6YR': 114,
	'7YR': 115,
	'8YR': 116,
	'9YR': 117,
	'10YR': 118,
	'11YR': 119,
	'12YR': 120,
	'13YR': 121,
	'14YR': 122,
	'15YR': 123,
	'16YR': 124,
	'17YR': 125,
};

export type TUpdateProductodyFetch = {
	brand_id: string,
	attribute: TProductInfoTypes,
	filter: {
		id: string
	},
	update: {
		[fieldName: string]: string
	}
}
export async function updateProductAction(
	dispatch: any,
	productId: string,
	attribute: TProductInfoTypes,
	fieldName: TProductInfoFieldNameTypes,
	value: string,
	brandId: string,
	token: string,
	translate: Function
) {
	const updateProductodyFetch: TUpdateProductodyFetch = {
		brand_id: brandId,
		attribute: attribute,
		filter: { id: productId },
		update: { [fieldName]: value }
	}
	const updateProductFetch = await catalogAPI.updateProductAPI(updateProductodyFetch, token)
	if (updateProductFetch.response.status !== 200 || updateProductFetch.data[0] === 0) {
		return false
	} else {
		return true
	}
}

export async function addLikeToProductAction(dispatch: any, catalogState: ICatalogState, userId: string, productId: string, setHaveLike: any, token: string) {
	setHaveLike(true)
	try {
		const likeFetch = await catalogAPI.addLikeAPI(userId, productId, token)
		if (likeFetch.response.status === 200) {
			const copyOfCatalogState: ICatalogState = JSON.parse(JSON.stringify(catalogState))
			const findCatalogItem = copyOfCatalogState.catalogItems.data.findIndex((item) => item.id === productId)
			if (findCatalogItem !== -1) {
				const objectToPush = {
					favorite_id: likeFetch.data.id,
					user_id: userId,
				} as IFavorite
				copyOfCatalogState.catalogItems.data[findCatalogItem]?.favorites.push(objectToPush)
				dispatch({
					type: CatalogTypes.GET_CATALOG,
					payload: copyOfCatalogState.catalogItems
				})
			}
			setHaveLike(true)
			return dispatch({
				type: CatalogTypes.ADD_LIKE_TO_PRODUCT,
				payload: likeFetch.data
			})
		} else {
			setHaveLike(false)
		}
	} catch (err) {
		setHaveLike(false)
		console.log(err);
	}
}

export async function removeLikeToProductAction(dispatch: any, catalogState: ICatalogState, productId: string, favorites: IFavorite[], userId: string, setHaveLike: any, token: string) {
	for (const favorite of favorites) {
		if (favorite.user_id === userId) {
			setHaveLike(false)
			try {
				const likeFetch = await catalogAPI.removeLikeAPI(favorite.favorite_id, token)
				if (likeFetch.response.status === 200) {
					const copyOfCatalogState: ICatalogState = JSON.parse(JSON.stringify(catalogState))
					const findCatalogItem = copyOfCatalogState.catalogItems.data?.findIndex((item) => item.id === productId)
					if (findCatalogItem !== -1) {
						copyOfCatalogState.catalogItems.data[findCatalogItem]?.favorites?.forEach((item, index: number) => {
							if (item.favorite_id === favorite.favorite_id) {
								copyOfCatalogState.catalogItems.data[findCatalogItem].favorites.splice(index, 1)
							}
						})
						dispatch({
							type: CatalogTypes.GET_CATALOG,
							payload: copyOfCatalogState.catalogItems
						})
					}
					return dispatch({
						type: CatalogTypes.REMOVE_LIKE_TO_PRODUCT,
						payload: favorite.user_id
					})
				} else {
					setHaveLike(true)
				}
			} catch (err) {
				setHaveLike(true)
				console.log(err);
			}
		}
	}
}

// Related Products
export async function getRelatedProductsAction(
	dispatch: any,
	catalogItems: ICatalogItemsState,
	bodyProductSearch: IBodyProductSearch,
	translate: any,
	token: string,
) {
	dispatch({ type: CatalogTypes.SET_IS_LOADING_RELATED_PRODUCTS, payload: true })
	try {
		const copyOfBodyProductSearch: IBodyProductSearch = JSON.parse(JSON.stringify(bodyProductSearch))
		let body: IBodyProductSearch = {
			options: {
				brand_id: copyOfBodyProductSearch.options.brand_id,
				favorite: "",
				with_images: true,
				profile: false,
				search: [],
				language_id: copyOfBodyProductSearch.options.language_id,
				order: [],
				group: [],
				limit: initialProductsQuantity,
				index: 40
			},
			filter: {
				reference: [],
				color: [],
				season: [],
				segmentation: [],
				division: [],
				family: [],
				gender: [],
				pvi: [],
				pvpr: [],
				tags: [],
				tiers: [],
			}
		}
		copyOfBodyProductSearch.options.index = copyOfBodyProductSearch.options.index - 10
		if (Math.sign(copyOfBodyProductSearch.options.index) === -1) {
			copyOfBodyProductSearch.options.index = 0
		}
		const products = await getAndOrderImagesCatalog(dispatch, catalogItems, copyOfBodyProductSearch, undefined, token)
		if (products) {
			if (products.data.length < 20) {
				body.options.index = 0
				const moreProducts = await getAndOrderImagesCatalog(dispatch, catalogItems, body, undefined, token)
				const allProducts = moreProducts ? products.data.concat(moreProducts.data) : products.data
				return dispatch({
					type: CatalogTypes.SET_RELATED_PRODUCTS,
					payload: {
						data: allProducts.slice(0, 30),
						count: allProducts.slice(0, 30).length
					}
				})
			}
			return dispatch({
				type: CatalogTypes.SET_RELATED_PRODUCTS,
				payload: {
					data: products.data.slice(0, 30),
					count: products.data.slice(0, 30).length
				}
			})
		}
	} catch (err) {
		// return notificationErr(catalogError.get_catalog_001, translate)
	} finally {
		dispatch({ type: CatalogTypes.SET_IS_LOADING_RELATED_PRODUCTS, payload: false })
	}
}

export async function addLikeToRelatedProductAction(dispatch: any, relatedProducts: ICatalogItemsState, userId: string, productId: string, setHaveLike: any, token: string) {
	setHaveLike(true)
	const copyOfRelatedProducts: ICatalogItemsState = JSON.parse(JSON.stringify(relatedProducts))
	const likeFetch = await catalogAPI.addLikeAPI(userId, productId, token)
	if (likeFetch.response.status === 200) {
		const objectToPush: IFavorite = {
			favorite_id: likeFetch.data.id,
			user_id: userId,
		}
		const findProduct = copyOfRelatedProducts.data.findIndex((item) => item.id === productId)
		copyOfRelatedProducts.data[findProduct].favorites.push(objectToPush)
		setHaveLike(true)
		return dispatch({
			type: CatalogTypes.SET_RELATED_PRODUCTS,
			payload: copyOfRelatedProducts
		})
	} else {
		setHaveLike(false)
	}
}

export async function removeLikeToRelatedProductAction(dispatch: any, relatedProducts: ICatalogItemsState, productId: string, favorites: IFavorite[], userId: string, setHaveLike: any, token: string) {
	favorites?.forEach(async (favorite) => {
		if (favorite.user_id === userId) {
			setHaveLike(false)
			const likeFetch = await catalogAPI.removeLikeAPI(favorite.favorite_id, token)
			if (likeFetch.response.status === 200) {
				const findProduct = relatedProducts.data.findIndex((item: any) => item.id === productId)
				const filteredFavorites = relatedProducts.data[findProduct].favorites.filter((item) => item.favorite_id !== favorite.favorite_id)
				const copyOfRelatedProducts: ICatalogItemsState = JSON.parse(JSON.stringify(relatedProducts))
				copyOfRelatedProducts.data[findProduct].favorites = filteredFavorites
				return dispatch({
					type: CatalogTypes.SET_RELATED_PRODUCTS,
					payload: copyOfRelatedProducts
				})
			} else {
				setHaveLike(true)
			}
		}
	});
}

// Search Engine
export async function setQuerySearchAction(dispatch: any, query: string) {
	return dispatch({ type: CatalogTypes.SET_QUERY_SEARCH, payload: query })
}

export async function addToBodySearchAction(
	dispatch: any,
	query: string,
	catalogState: ICatalogState,
	translate: any,
	signal: AbortSignal | undefined,
	token: string,
) {
	try {
		const copyOfCatalogState: ICatalogState = JSON.parse(JSON.stringify(catalogState))
		copyOfCatalogState.bodyProductSearch.options.index = 0
		copyOfCatalogState.bodyProductSearch.options.search.push(`%${query}%`)
		getCatalogAction(dispatch, copyOfCatalogState.catalogItems, copyOfCatalogState.bodyProductSearch, translate, signal, token)
	} catch (err) {
		console.log(err);
	}
}

export async function removeFromBodySearchAction(
	dispatch: any,
	query: string,
	catalogState: ICatalogState,
	translate: any,
	signal: AbortSignal | undefined,
	token: string,
) {
	try {
		const copyOfCatalogState: ICatalogState = JSON.parse(JSON.stringify(catalogState))
		copyOfCatalogState.bodyProductSearch.options.index = 0
		const filteredSearch = copyOfCatalogState.bodyProductSearch.options.search.filter((item) => item !== query)
		copyOfCatalogState.bodyProductSearch.options.search = filteredSearch
		copyOfCatalogState.bodyProductSearchTemp = copyOfCatalogState.bodyProductSearch
		getCatalogAction(dispatch, copyOfCatalogState.catalogItems, copyOfCatalogState.bodyProductSearch, translate, signal, token)
	} catch (err) {
		console.log(err);
	}
}

export async function addToQuerySearchArrayAction(dispatch: any, value: TQuerySearchArray) {
	return dispatch({ type: CatalogTypes.ADD_TO_QUERY_SEARCH_ARRAY, payload: value })
}

export async function removeFromQuerySearchArrayAction(dispatch: any, value: TQuerySearchArray) {
	return dispatch({ type: CatalogTypes.REMOVE_FROM_QUERY_SEARCH_ARRAY, payload: value })
}

export async function removeAllFromBodySearchAction(
	dispatch: any,
	catalogState: ICatalogState,
	translate: any,
	signal: AbortSignal | undefined,
	token: string,
) {
	try {
		const copyOfCatalogState: ICatalogState = JSON.parse(JSON.stringify(catalogState))
		copyOfCatalogState.bodyProductSearch.options.limit = initialProductsQuantity
		copyOfCatalogState.bodyProductSearch.options.index = 0
		copyOfCatalogState.bodyProductSearch.options.search = []
		copyOfCatalogState.bodyProductSearch.options.favorite = ""
		copyOfCatalogState.bodyProductSearch.filter = {
			reference: [],
			color: [],
			season: [],
			segmentation: [],
			division: [],
			family: [],
			gender: [],
			pvi: [],
			pvpr: [],
			tags: [],
			tiers: [],
		}
		copyOfCatalogState.bodyProductSearchTemp = copyOfCatalogState.bodyProductSearch
		copyOfCatalogState.querysearchArray = []
		getCatalogAction(dispatch, copyOfCatalogState.catalogItems, copyOfCatalogState.bodyProductSearch, translate, signal, token)
	} catch (err) {
		console.log(err);
	}
}

export async function removeFromBodySearchFilterAction(
	dispatch: any,
	value: TQuerySearchArray,
	catalogState: ICatalogState,
	translate: Function,
	signal: AbortSignal | undefined,
	token: string,
) {
	const { field } = value
	try {
		const copyOfCatalogState: ICatalogState = JSON.parse(JSON.stringify(catalogState))
		copyOfCatalogState.bodyProductSearch.options.index = 0
		const filteredQuerySearchArray = copyOfCatalogState.querysearchArray.filter(
			(item) => item.value[0] !== value?.value[0]
		)
		const filteredArray = (copyOfCatalogState.bodyProductSearch.filter[field as keyof typeof copyOfCatalogState.bodyProductSearch.filter] as string[]).filter((item) => !value.value.includes(item as string));
		copyOfCatalogState.bodyProductSearch.filter[field as keyof typeof copyOfCatalogState.bodyProductSearch.filter] = filteredArray as any;
		dispatch({
			type: CatalogTypes.SET_BODY_SEARCH_FILTER,
			payload: {
				querySearchArray: filteredQuerySearchArray,
			}
		})
		await getCatalogAction(dispatch, copyOfCatalogState.catalogItems, copyOfCatalogState.bodyProductSearch, translate, signal, token)
	} catch (err) {
		console.log(err);
	}
}

// Sort By
export async function setSortByAction(dispatch: any, sortBy: TSortBy, catalogState: ICatalogState, translate: any, signal: AbortSignal | undefined, token: string) {
	const copyOfCatalogState: ICatalogState = JSON.parse(JSON.stringify(catalogState))
	copyOfCatalogState.bodyProductSearch.options.order = [sortBy]
	copyOfCatalogState.bodyProductSearch.options.index = 0
	dispatch({ type: CatalogTypes.SET_IS_LOADING, payload: true })
	dispatch({ type: CatalogTypes.SET_SORT_BY, payload: sortBy })
	try {
		await getCatalogAction(dispatch, copyOfCatalogState.catalogItems, copyOfCatalogState.bodyProductSearch, translate, signal, token)
	} catch (error) {
		console.log('error');
	} finally {
		return dispatch({ type: CatalogTypes.SET_IS_LOADING, payload: false })
	}
}

export async function setSortByHaveImagesAction(dispatch: any, haveImages: boolean | null, catalogItems: ICatalogItemsState, bodyProductSearch: IBodyProductSearch, translate: any, signal: AbortSignal | undefined, token: string) {
	dispatch({ type: CatalogTypes.SET_IS_LOADING, payload: true })
	try {
		const copyOfBodyProductSearch: IBodyProductSearch = JSON.parse(JSON.stringify(bodyProductSearch))
		copyOfBodyProductSearch.options.with_images = haveImages
		await getCatalogAction(dispatch, catalogItems, copyOfBodyProductSearch, translate, signal, token)
	} catch (error) {
		console.log(error);
	} finally {
		return dispatch({ type: CatalogTypes.SET_IS_LOADING, payload: false })
	}
}

type TgetProductAttributes = {
	dispatch: any,
	brandId: string,
	translate: any,
	token: string
}
export async function getProductAttributesAction({ dispatch, brandId, translate, token }: TgetProductAttributes) {
	const [seasons, divisions, genders, families, colors, segmentations] = await Promise.all([
		getProductAttributesAPI(brandId, SEASON_FILTER, token),
		getProductAttributesAPI(brandId, DIVISION_FILTER, token),
		getProductAttributesAPI(brandId, GENDER_FILTER, token),
		getProductAttributesAPI(brandId, FAMILY_FILTER, token),
		getProductAttributesAPI(brandId, COLOR_FILTER, token),
		getProductAttributesAPI(brandId, SEGMENTATION_FILTER, token),
	])

	const haveSeasons = seasons.response.status === 200 && seasons.data.length > 0;
	const haveDivisions = divisions.response.status === 200 && divisions.data.length > 0;
	const haveGenders = genders.response.status === 200 && genders.data.length > 0;
	const haveFamilies = families.response.status === 200 && families.data.length > 0;
	const haveColors = colors.response.status === 200 && colors.data.length > 0;
	const haveSegmentations = segmentations.response.status === 200 && segmentations.data.length > 0;

	dispatch({
		type: CatalogTypes.SET_PRODUCT_ATTRIBUTES,
		payload: {
			season: haveSeasons ? seasons.data : [],
			division: haveDivisions ? divisions.data : [],
			gender: haveGenders ? genders.data : [],
			family: haveFamilies ? families.data : [],
			color: haveColors ? colors.data : [],
			segmentation: haveSegmentations ? segmentations.data : [],
		}
	})
}

///////////////////////////////////
///////////////////////////////////

interface ICatalogFetchDataResponse {
	id: string,
	brand: string,
	brand_id: string,
	ranking: number,
	units: number,
	favorites: IFavorite[],
	name: string,
	pvi: number,
	pvpr: number,
	reference: string,
	color: string,
	color_code: string,
	images: string[],
}

async function getAndOrderImagesCatalog(dispatch: any, catalogItems: ICatalogItemsState, body: any, signal: AbortSignal | undefined, token: string, isPagination: boolean = false) {
	try {
		const catalogFetch = await catalogAPI.getCatalog(body, token, signal)
		dispatch({ type: CatalogTypes.SET_IS_EMPTY_PRODUCTS, payload: false })
		if (catalogFetch.response.status === 404 || catalogFetch.data.data.length === 0) {
			dispatch({ type: CatalogTypes.SET_IS_EMPTY_PRODUCTS, payload: true })
			dispatch({ type: CatalogTypes.RESET_CATALOG_ITEMS })
		}
		if (catalogFetch.data.data?.length < showMoreProducts) {
			dispatch({ type: CatalogTypes.SET_THERE_ARE_MORE_PRODUCTS, payload: false })
		} else {
			dispatch({ type: CatalogTypes.SET_THERE_ARE_MORE_PRODUCTS, payload: true })
		}
		const products = catalogFetch.data.data as ICatalogFetchDataResponse[]

		type IImageWithNumber = {
			original: string;
			number: number;
		}

		await Promise.all(products.map(async (item) => {
			if (item?.images && item.images.length > 0) {
				let imagesWithNumbers: IImageWithNumber[] = [];
				let imagesWithoutNumbers: string[] = [];
				item.images.forEach((image) => {
					const number = parseInt(getExtension(image).split('.')[0], 10);
					if (!isNaN(number)) {
						imagesWithNumbers.push({ original: image, number });
					} else {
						imagesWithoutNumbers.push(image);
					}
				});
				imagesWithNumbers.sort((a, b) => a.number - b.number);
				const indexEndsWithOne = imagesWithNumbers.findIndex((image) => image.number % 10 === 1);
				if (indexEndsWithOne > -1) {
					const [imageEndsWithOne] = imagesWithNumbers.splice(indexEndsWithOne, 1);
					imagesWithNumbers.unshift(imageEndsWithOne);
				}
				const uniqueNumericImages = Array.from(new Set(imagesWithNumbers.map((image) => image.original)));
				item.images = uniqueNumericImages.concat(imagesWithoutNumbers);
			}
		}));
		// products?.forEach(async (item: any) => {
		// 	if (item?.images && item?.images?.length > 0) {
		// 		item.images.sort((a: any, b: any) => {
		// 			const extA = getExtension(a);
		// 			const extB = getExtension(b);
		// 			const numA = parseInt(extA.split('.')[0]);
		// 			const numB = parseInt(extB.split('.')[0]);
		// 			if (numA >= 1 && numA <= 9 && numB >= 1 && numB <= 9) {
		// 				return numA - numB;
		// 			} else if (numA >= 1 && numA <= 9) {
		// 				return -1;
		// 			} else if (numB >= 1 && numB <= 9) {
		// 				return 1;
		// 			} else {
		// 				return 0;
		// 			}
		// 		});
		// 	}
		// });

		// products?.forEach(async (item: any) => {
		// 	if (item?.images && item?.images?.length > 0) {
		// 		item.images.reverse()
		// 		item.images.forEach((element: any, index: number) => {
		// 			const ext = getExtension(element)

		// 			const getImageNumber = ext.split('.')[0]
		// 			console.log(ext, getImageNumber[1]);
		// 			if ((getImageNumber[1] == 1) && Number(getImageNumber[0]) == 0) {
		// 				// if (getImageNumber[1] == 1 && Number(getImageNumber[0]) !== 1) {
		// 				item.images.slice(index, 1)
		// 				item.images.unshift(element)
		// 			}
		// 			const result = item.images.reduce((acc: any, item: any) => {
		// 				if (!acc.includes(item)) {
		// 					acc.push(item);
		// 				}
		// 				return acc;
		// 			}, [])
		// 			item.images = result
		// 		});
		// 	}
		// });
		return {
			count: catalogFetch.data.count,
			data: products,
		} as ICatalogItemsState

	} catch (error) {
		// console.log(error);
	}
}

function getExtension(url: any) {
	return (url = url.substr(1 + url.lastIndexOf("/")).split('?')[0]).split('#')[0].substr(url.lastIndexOf(".") - 2)
}