import { basePath } from "./utils/config"

export const getCommercialInfoAPI = async (tenantId: string, token: string): Promise<any> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/brand_profile/get_brand_comercial_agents?tenant_id=${tenantId}`, params)
	const data = await response.json()
	return data
}

export const createCommercialAgentAPI = async (tenantId: string, agent: any, token: string): Promise<any> => {
	const params = {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(agent),
	}
	const response = await fetch(`${basePath}/brand_profile/create_brand_comercial_agent?brand_id=${tenantId}`, params)
	const data = await response.json()
	return { response, data }
}

export const updateCommercialInfoAPI = async (newContact: any, token: string): Promise<any> => {
	const params = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(newContact),
	}
	const response = await fetch(`${basePath}/brand_profile/update_brand_comercial_agent`, params)
	const data = await response.json()
	return data
}

export const removeCommercialAgentAPI = async (contactId: string, token: string): Promise<any> => {
	const params = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		}
	}
	const response = await fetch(`${basePath}/brand_profile/delete_brand_comercial_agent?agent_id=${contactId}`, params)
	const data = await response.json()
	return data
}

export const getDivisionsByAgentIdAPI = async (brandId: string, token: string): Promise<any> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		}
	}
	const response = await fetch(`${basePath}/comercial_agent_profile/get_division?agent_id=${brandId}`, params)
	const data = await response.json()
	return data
}