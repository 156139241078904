import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import spanishTranslation from 'assets/locale/es/es';
import englishTranslation from 'assets/locale/en/en';
import franceTranslation from 'assets/locale/fr/fr';
import italianTranslation from 'assets/locale/it/it';

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      es: { translation: spanishTranslation },
      en: { translation: englishTranslation },
      fr: { translation: franceTranslation },
      it: { translation: italianTranslation },
    }
  });

export default i18n;