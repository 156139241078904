import { basePath } from "./utils/config"

export const getClientsAPI = async (token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			scope: "tenant",
			populated: true,
			country_id: "",
			search: "",
			index: 0,
			limit: 3000
		}),
	}
	const response = await fetch(`${basePath}/superadmin/search`, params)
	const data = await response.json()
	return { response, data }
}

export const searchClientsAPI = async (query: string, token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			scope: "tenant",
			populated: true,
			country_id: "",
			search: query,
			index: 0,
			limit: 5000
		}),
	}
	const response = await fetch(`${basePath}/superadmin/search`, params)
	const data = await response.json()
	return { response, data }
}