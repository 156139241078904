export const GET_MULTIMEDIA = 'GET_MULTIMEDIA'
export const SET_IS_LOADING_MEDIA = 'SET_IS_LOADING_MEDIA'
export const SET_IS_LOADING_MORE_MEDIA = 'SET_IS_LOADING_MORE_MEDIA'
export const CREATE_MULTIMEDIA = 'CREATE_MULTIMEDIA'
export const UPDATE_MULTIMEDIA = 'UPDATE_MULTIMEDIA'
export const SET_IS_LOADING_CREATE_MULTIMEDIA = 'SET_IS_LOADING_CREATE_MULTIMEDIA'
export const REMOVE_MULTIMEDIA = 'REMOVE_MULTIMEDIA'
export const SEARCH_MEDIA = 'SEARCH_MEDIA'
export const RESET_MEDIA = 'RESET_MEDIA'
export const SET_ITEM_SELECTED = 'SET_ITEM_SELECTED'
export const SET_IS_OPEN_EDIT_MODAL = 'SET_IS_OPEN_EDIT_MODAL'