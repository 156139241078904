import { IinitialCatalogCorrelationsState } from 'interfaces/catalogCorrelations.interface';
import * as CatalogCorrelationsType from './catalogCorrelations.types'

export default function catalogCorrelationsReducer(state: IinitialCatalogCorrelationsState, action: any) {
	const { type, payload } = action;

	switch (type) {
		case CatalogCorrelationsType.SET_CORRELATIONS:
			const setCorrelations: IinitialCatalogCorrelationsState = {
				...state,
				correlations: {
					...state.correlations,
					[payload.type]: payload.data
				}
			}
			return setCorrelations

		case CatalogCorrelationsType.SET_IS_LOADING:
			const setIsLoading: IinitialCatalogCorrelationsState = {
				...state,
				isLoading: payload
			}
			return setIsLoading

		case CatalogCorrelationsType.SET_IS_LOADING_ASSIGN_GENERICS:
			const setIsLoadingAssignGenerics: IinitialCatalogCorrelationsState = {
				...state,
				isLoadingAssignGenerics: payload
			}
			return setIsLoadingAssignGenerics

		default:
			return state
	}
}