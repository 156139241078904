export const countriesTranslation = {
  'google-address-form_country_AF': 'Afghanistan',
  'google-address-form_country_AX': 'Îles Åland',
  'google-address-form_country_AL': 'Albanie',
  'google-address-form_country_DZ': 'Algérie',
  'google-address-form_country_AS': 'Samoa américaines',
  'google-address-form_country_AD': 'Andorre',
  'google-address-form_country_AO': 'Angola',
  'google-address-form_country_AI': 'Anguilla',
  'google-address-form_country_AQ': 'Antarctique',
  'google-address-form_country_AG': 'Antigua-et-Barbuda',
  'google-address-form_country_AR': 'Argentine',
  'google-address-form_country_AM': 'Arménie',
  'google-address-form_country_AW': 'Aruba',
  'google-address-form_country_AU': 'Australie',
  'google-address-form_country_AT': 'Autriche',
  'google-address-form_country_AZ': 'Azerbaïdjan',
  'google-address-form_country_BS': 'Bahamas',
  'google-address-form_country_BH': 'Bahreïn',
  'google-address-form_country_BD': 'Bangladesh',
  'google-address-form_country_BB': 'Barbade',
  'google-address-form_country_BY': 'Biélorussie',
  'google-address-form_country_BE': 'Belgique',
  'google-address-form_country_BZ': 'Belize',
  'google-address-form_country_BJ': 'Bénin',
  'google-address-form_country_BM': 'Bermudes',
  'google-address-form_country_BT': 'Bhoutan',
  'google-address-form_country_BO': 'Bolivie',
  'google-address-form_country_BQ': 'Bonaire, Saint-Eustache et Saba',
  'google-address-form_country_BA': 'Bosnie-Herzégovine',
  'google-address-form_country_BW': 'Botswana',
  'google-address-form_country_BV': 'Bouvet Island',
  'google-address-form_country_BR': 'Brésil',
  'google-address-form_country_IO': 'Territoire britannique de l\'océan Indien',
  'google-address-form_country_BN': 'Brunei Darussalam',
  'google-address-form_country_BG': 'Bulgarie',
  'google-address-form_country_BF': 'Burkina Faso',
  'google-address-form_country_BI': 'Burundi',
  'google-address-form_country_CV': 'Cap-Vert',
  'google-address-form_country_KH': 'Cambodge',
  'google-address-form_country_CM': 'Cameroun',
  'google-address-form_country_CA': 'Canada',
  'google-address-form_country_KY': 'Îles Caïmans',
  'google-address-form_country_CF': 'République centrafricaine',
  'google-address-form_country_TD': 'Tchad',
  'google-address-form_country_CL': 'Chili',
  'google-address-form_country_CN': 'Chine',
  'google-address-form_country_CX': 'Île Christmas',
  'google-address-form_country_CC': 'Îles Cocos (Keeling)',
  'google-address-form_country_CO': 'Colombie',
  'google-address-form_country_KM': 'Comores',
  'google-address-form_country_CG': 'Congo (Brazzaville)',
  'google-address-form_country_CD': 'Congo (République démocratique)',
  'google-address-form_country_CK': 'Îles Cook',
  'google-address-form_country_CR': 'Costa Rica',
  'google-address-form_country_HR': 'Croatie',
  'google-address-form_country_CU': 'Cuba',
  'google-address-form_country_CW': 'Curaçao',
  'google-address-form_country_CY': 'Chypre',
  'google-address-form_country_CZ': 'Tchéquie',
  'google-address-form_country_DK': 'Danemark',
  'google-address-form_country_DJ': 'Djibouti',
  'google-address-form_country_DM': 'Dominique',
  'google-address-form_country_DO': 'République dominicaine',
  'google-address-form_country_EC': 'Équateur',
  'google-address-form_country_EG': 'Égypte',
  'google-address-form_country_SV': 'El Salvador',
  'google-address-form_country_GQ': 'Guinée équatoriale',
  'google-address-form_country_ER': 'Érythrée',
  'google-address-form_country_EE': 'Estonie',
  'google-address-form_country_SZ': 'Eswatini',
  'google-address-form_country_ET': 'Éthiopie',
  'google-address-form_country_FK': 'Îles Falkland (Malouines)',
  'google-address-form_country_FO': 'Îles Féroé',
  'google-address-form_country_FJ': 'Fidji',
  'google-address-form_country_FI': 'Finlande',
  'google-address-form_country_FR': 'France',
  'google-address-form_country_GF': 'Guyane française',
  'google-address-form_country_PF': 'Polynésie française',
  'google-address-form_country_TF': 'Terres australes françaises',
  'google-address-form_country_GA': 'Gabon',
  'google-address-form_country_GM': 'Gambie',
  'google-address-form_country_GE': 'Géorgie',
  'google-address-form_country_DE': 'Allemagne',
  'google-address-form_country_GH': 'Ghana',
  'google-address-form_country_GI': 'Gibraltar',
  'google-address-form_country_GR': 'Grèce',
  'google-address-form_country_GL': 'Groenland',
  'google-address-form_country_GD': 'Grenade',
  'google-address-form_country_GP': 'Guadeloupe',
  'google-address-form_country_GU': 'Guam',
  'google-address-form_country_GT': 'Guatemala',
  'google-address-form_country_GG': 'Guernesey',
  'google-address-form_country_GN': 'Guinée',
  'google-address-form_country_GW': 'Guinée-Bissau',
  'google-address-form_country_GY': 'Guyana',
  'google-address-form_country_HT': 'Haïti',
  'google-address-form_country_HM': 'Îles Heard-et-MacDonald',
  'google-address-form_country_VA': 'Saint-Siège',
  'google-address-form_country_HN': 'Honduras',
  'google-address-form_country_HK': 'Hong Kong',
  'google-address-form_country_HU': 'Hongrie',
  'google-address-form_country_IS': 'Islande',
  'google-address-form_country_IN': 'Inde',
  'google-address-form_country_ID': 'Indonésie',
  'google-address-form_country_IR': 'Iran',
  'google-address-form_country_IQ': 'Iraq',
  'google-address-form_country_IE': 'Irlande',
  'google-address-form_country_IM': 'Île de Man',
  'google-address-form_country_IL': 'Israël',
  'google-address-form_country_IT': 'Italie',
  'google-address-form_country_JM': 'Jamaïque',
  'google-address-form_country_JP': 'Japon',
  'google-address-form_country_JE': 'Jersey',
  'google-address-form_country_JO': 'Jordanie',
  'google-address-form_country_KZ': 'Kazakhstan',
  'google-address-form_country_KE': 'Kenya',
  'google-address-form_country_KI': 'Kiribati',
  'google-address-form_country_KP': 'Corée du Nord',
  'google-address-form_country_KR': 'Corée du Sud',
  'google-address-form_country_KW': 'Koweït',
  'google-address-form_country_KG': 'Kirghizistan',
  'google-address-form_country_LA': 'Laos',
  'google-address-form_country_LV': 'Lettonie',
  'google-address-form_country_LB': 'Liban',
  'google-address-form_country_LS': 'Lesotho',
  'google-address-form_country_LR': 'Liberia',
  'google-address-form_country_LY': 'Libye',
  'google-address-form_country_LI': 'Liechtenstein',
  'google-address-form_country_LT': 'Lituanie',
  'google-address-form_country_LU': 'Luxembourg',
  'google-address-form_country_MO': 'Macao',
  'google-address-form_country_MG': 'Madagascar',
  'google-address-form_country_MW': 'Malawi',
  'google-address-form_country_MY': 'Malaisie',
  'google-address-form_country_MV': 'Maldives',
  'google-address-form_country_ML': 'Mali',
  'google-address-form_country_MT': 'Malte',
  'google-address-form_country_MH': 'Îles Marshall',
  'google-address-form_country_MQ': 'Martinique',
  'google-address-form_country_MR': 'Mauritanie',
  'google-address-form_country_MU': 'Maurice',
  'google-address-form_country_YT': 'Mayotte',
  'google-address-form_country_MX': 'Mexique',
  'google-address-form_country_FM': 'Micronésie',
  'google-address-form_country_MD': 'Moldavie',
  'google-address-form_country_MC': 'Monaco',
  'google-address-form_country_MN': 'Mongolie',
  'google-address-form_country_ME': 'Monténégro',
  'google-address-form_country_MS': 'Montserrat',
  'google-address-form_country_MA': 'Maroc',
  'google-address-form_country_MZ': 'Mozambique',
  'google-address-form_country_MM': 'Myanmar',
  'google-address-form_country_NA': 'Namibie',
  'google-address-form_country_NR': 'Nauru',
  'google-address-form_country_NP': 'Népal',
  'google-address-form_country_NL': 'Pays-Bas',
  'google-address-form_country_NC': 'Nouvelle-Calédonie',
  'google-address-form_country_NZ': 'Nouvelle-Zélande',
  'google-address-form_country_NI': 'Nicaragua',
  'google-address-form_country_NE': 'Niger',
  'google-address-form_country_NG': 'Nigéria',
  'google-address-form_country_NU': 'Niue',
  'google-address-form_country_NF': 'Île Norfolk',
  'google-address-form_country_MK': 'Macédoine du Nord',
  'google-address-form_country_MP': 'Îles Mariannes du Nord',
  'google-address-form_country_NO': 'Norvège',
  'google-address-form_country_OM': 'Oman',
  'google-address-form_country_PK': 'Pakistan',
  'google-address-form_country_PW': 'Palaos',
  'google-address-form_country_PS': 'Palestine',
  'google-address-form_country_PA': 'Panama',
  'google-address-form_country_PG': 'Papouasie-Nouvelle-Guinée',
  'google-address-form_country_PY': 'Paraguay',
  'google-address-form_country_PE': 'Pérou',
  'google-address-form_country_PH': 'Philippines',
  'google-address-form_country_PN': 'Îles Pitcairn',
  'google-address-form_country_PL': 'Pologne',
  'google-address-form_country_PT': 'Portugal',
  'google-address-form_country_PR': 'Porto Rico',
  'google-address-form_country_QA': 'Qatar',
  'google-address-form_country_RE': 'La Réunion',
  'google-address-form_country_RO': 'Roumanie',
  'google-address-form_country_RU': 'Russie',
  'google-address-form_country_RW': 'Rwanda',
  'google-address-form_country_BL': 'Saint-Barthélemy',
  'google-address-form_country_SH': 'Sainte-Hélène',
  'google-address-form_country_KN': 'Saint-Kitts-et-Nevis',
  'google-address-form_country_LC': 'Sainte-Lucie',
  'google-address-form_country_MF': 'Saint-Martin',
  'google-address-form_country_PM': 'Saint-Pierre-et-Miquelon',
  'google-address-form_country_VC': 'Saint-Vincent-et-les-Grenadines',
  'google-address-form_country_WS': 'Samoa',
  'google-address-form_country_SM': 'Saint-Marin',
  'google-address-form_country_ST': 'Sao Tomé-et-Principe',
  'google-address-form_country_SA': 'Arabie Saoudite',
  'google-address-form_country_SN': 'Sénégal',
  'google-address-form_country_RS': 'Serbie',
  'google-address-form_country_SC': 'Seychelles',
  'google-address-form_country_SL': 'Sierra Leone',
  'google-address-form_country_SG': 'Singapour',
  'google-address-form_country_SX': 'Saint-Martin (partie néerlandaise)',
  'google-address-form_country_SK': 'Slovaquie',
  'google-address-form_country_SI': 'Slovénie',
  'google-address-form_country_SB': 'Îles Salomon',
  'google-address-form_country_SO': 'Somalie',
  'google-address-form_country_ZA': 'Afrique du Sud',
  'google-address-form_country_GS': 'Géorgie du Sud et les Îles Sandwich du Sud',
  'google-address-form_country_SS': 'Soudan du Sud',
  'google-address-form_country_ES': 'Espagne',
  'google-address-form_country_LK': 'Sri Lanka',
  'google-address-form_country_SD': 'Soudan',
  'google-address-form_country_SR': 'Suriname',
  'google-address-form_country_SJ': 'Svalbard et Jan Mayen',
  'google-address-form_country_SE': 'Suède',
  'google-address-form_country_CH': 'Suisse',
  'google-address-form_country_SY': 'Syrie',
  'google-address-form_country_TW': 'Taïwan',
  'google-address-form_country_TJ': 'Tadjikistan',
  'google-address-form_country_TZ': 'Tanzanie',
  'google-address-form_country_TH': 'Thaïlande',
  'google-address-form_country_TL': 'Timor oriental',
  'google-address-form_country_TG': 'Togo',
  'google-address-form_country_TK': 'Tokelau',
  'google-address-form_country_TO': 'Tonga',
  'google-address-form_country_TT': 'Trinité-et-Tobago',
  'google-address-form_country_TN': 'Tunisie',
  'google-address-form_country_TR': 'Turquie',
  'google-address-form_country_TM': 'Turkménistan',
  'google-address-form_country_TC': 'Îles Turques-et-Caïques',
  'google-address-form_country_TV': 'Tuvalu',
  'google-address-form_country_UG': 'Ouganda',
  'google-address-form_country_UA': 'Ukraine',
  'google-address-form_country_AE': 'Émirats arabes unis',
  'google-address-form_country_GB': 'Royaume-Uni',
  'google-address-form_country_US': 'États-Unis',
  'google-address-form_country_UY': 'Uruguay',
  'google-address-form_country_UZ': 'Ouzbékistan',
  'google-address-form_country_VU': 'Vanuatu',
  'google-address-form_country_VE': 'Venezuela',
  'google-address-form_country_VN': 'Vietnam',
  'google-address-form_country_EH': 'Sahara occidental',
  'google-address-form_country_YE': 'Yémen',
  'google-address-form_country_ZM': 'Zambie',
  'google-address-form_country_ZW': 'Zimbabwe',
}