export const userRole = 'user'
export const adminRole = 'admin'
export const managerRole = 'manager'
export const superAdminRole = 'superadmin'
export const initialProductsQuantity = 20
export const showMoreProducts = 20
// Client
export const clientLocal = 'http://localhost:4202'
export const clientProd = 'https://portalwh.hub4retail.com'
export const clientStaging = 'https://sta.portalwh.hub4retail.com'
export const clientDev = 'https://dev.portalwh.hub4retail.com'
export const clientLanding = 'https://portal.hub4retail.com'
export const clientLocalKey = 'local'
export const clientProdKey = 'prod'
export const clientStagingKey = 'staging'
export const clientDevKey = 'dev'

export const superAdminUsers = [
  process.env.REACT_APP_SUPERADMIN_EMAIL_1
]