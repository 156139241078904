/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo } from 'react'
import CatalogGenericContext from './CatalogGenericContext'
import initialCatalogGenericsState from './initialCatalogGenericsState'
import catalogGenericReducer from './reducer/catalogGeneric.reducer'
import * as action from "./reducer/catalogGeneric.actions";
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { IGenericType, TGenericValues, TUpdateGenericBody } from 'interfaces/generics.interface';
import { ChildrenProps } from 'types';

function CatalogGenericProvider(props: ChildrenProps) {
	const [genericState, dispatch] = useReducer(catalogGenericReducer, initialCatalogGenericsState)
	const { t: translate } = useTranslation()
	const { getAccessTokenSilently } = useAuth0()

	const getGenerics = useCallback(async ({ type }: { type: IGenericType }) => {
		const token = await getAccessTokenSilently()
		return action.getGenericsAction({ dispatch, type, token, translate })
	}, [translate])

	const addGenerics = useCallback(async ({ type, generics }: { type: IGenericType, generics: TGenericValues[] }) => {
		const token = await getAccessTokenSilently()
		return action.addGenericsAction({ dispatch, type, generics, token, translate })
	}, [translate])

	const updateGeneric = useCallback(async ({ body }: { body: TUpdateGenericBody }) => {
		const token = await getAccessTokenSilently()
		return action.updateGenericAction({ dispatch, genericState, body, token, translate }) as Promise<boolean>
	}, [genericState, translate])

	const memoProvider = useMemo(
		() => ({
			...genericState,
			getGenerics,
			addGenerics,
			updateGeneric
		}), [
		genericState,
		getGenerics,
		addGenerics,
		updateGeneric
	]
	);

	return (
		<CatalogGenericContext.Provider value={memoProvider}>
			{props.children}
		</CatalogGenericContext.Provider>
	)
}

export default memo(CatalogGenericProvider)