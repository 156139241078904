import { CreateMultimediaBody, IBodyMultimediaFetch } from "interfaces/multimedia.interface"
import { basePath } from "./utils/config"

export const getMultimediaUsagesAPI = async (token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			filter: {},
			select: "",
			limit: 1000,
			index: 0
		}),
	}
	const response = await fetch(`${basePath}/crud_multimedia_usage/findManyCrud_multimedia_usage`, params)
	const data = await response.json()
	return { response, data }
}

export const findMultimediaAPI = async (body: IBodyMultimediaFetch, signal: AbortSignal | undefined, token: string): Promise<any> => {
	const params = {
		signal: signal,
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/multimedia/find_multimedia`, params)
	const data = await response.json()
	return { response, data }
}

export const removeMultimediaAPI = async (imageId: string, token: string): Promise<any> => {
	const params = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		}
	}
	const response = await fetch(`${basePath}/multimedia/delete_multimedia?id=${imageId}`, params)
	const data = await response.json()
	return { response, data }
}

export type TUpdateMultimediaBody = {
	id: string;
	multimedia: {
		title: string;
		description: string;
		usage_id: string | null;
		enable_download: boolean;
		start: string | Date;
		end: string | Date;
	}
	division_id: string[];
	gender_id: string[];
	product_ids: string[];
	season_ids: string[];
	tags: string[];
};
export const updateMultimediaAPI = async (body: TUpdateMultimediaBody, token: string): Promise<any> => {
	const params = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/multimedia/update_multimedia`, params)
	const data = await response.json()
	return { response, data }
}

export const createMultimediaAPI = async (body: CreateMultimediaBody, token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/multimedia/register_multimedia`, params)
	const data = await response.json()
	return { response, data }
}