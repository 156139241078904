import ReactDOM from 'react-dom/client'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import 'utils/i18n'
import 'antd/dist/reset.css'
import './index.scss'
import { message } from 'antd'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />)

serviceWorkerRegistration.register({
  onUpdate: (registration: any) => {
    const reload = () => window.location.reload();
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      registration.update()
      message.info("Nueva versión. Actualizando la aplicación", reload);
    }
  }
})
