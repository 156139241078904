import { ICreateMapping } from "interfaces/catalogCsvUpload.interface"
import { basePath } from "./utils/config"
import { IUpdateSeasonBodyProps, TassignImagesAPIProps, TassignImagesAPIResponse, TgetImagesPatternAPIResponse, TgetUnmatchedImagesAPIProps, TgetUnmatchedImagesAPIResponse, TmatchImagesAPIResponse, TproccessCatalogCSVFileAPIProps, TsaveCatalogCSVFileAPIResponse, TsaveImagesPatternAPIProps, TsaveSaleFileAPIProps, TuploadDiscardedImagesAPIResponse, TUploadImagesItem, TuploadProductImagesAPIProps, TuploadProductImagesAPIResponse } from "./responseInterfaces/catalogCSVUpload.response.interface"

export const getCAtalogReportsByUploadAPI = async (brandId: string, token: string): Promise<any> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/upload_control/uploads?brand_id=${brandId}`, params)
	const data = await response.json()
	return { response, data }
}

export const publishCatalogUploadAPI = async (uploadId: string, brandId: string, publish: boolean, token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			brand_id: brandId,
			upload_id: uploadId,
			publish: publish
		}),
	}
	const response = await fetch(`${basePath}/upload_control/publish`, params)
	const data = await response.json()
	return { response, data }
}

export const rejectCatalogUploadAPI = async (uploadId: string, brandId: string, token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			brand_id: brandId,
			upload_id: uploadId,
		}),
	}
	const response = await fetch(`${basePath}/upload_control/reject`, params)
	const data = await response.json()
	return { response, data }
}

export const getCatalogReportsBySeasonAPI = async (brandId: string, enabled: boolean, token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			brand_id: brandId,
			start: null,
			end: null,
			enabled: enabled
		}),
	}
	const response = await fetch(`${basePath}/active_season/get_season`, params)
	const data = await response.json()
	return { response, data }
}

export const updateSeasonAPI = async (body: IUpdateSeasonBodyProps, token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/active_season/update_season`, params)
	const data = await response.json()
	return { response, data }
}

export const getMappingKeysAPI = async (token: string): Promise<any> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/csv_mapping/get_catalog_keys`, params)
	const data = await response.json()
	return { response, data }
}

export const getMyMappingsAPI = async (brandId: string, token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			brand_id: brandId,
			category: "catalog"
		}),
	}
	const response = await fetch(`${basePath}/csv_mapping/get_maps?brand_id=${brandId}`, params)
	const data = await response.json()
	return { response, data }
}

export const deleteMappingAPI = async (mappingId: string, token: string): Promise<any> => {
	const params = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/csv_mapping/delete_map?id=${mappingId}`, params)
	const data = await response.json()
	return { response, data }
}

export const createMappingAPI = async (body: ICreateMapping, token: string): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/csv_mapping/map_csv`, params)
	const data = await response.json()
	return { response, data }
}

export const saveCatalogCSVFileAPI = async ({ brandId, original_name, token, original_content_type, encoding }: TsaveSaleFileAPIProps): Promise<TsaveCatalogCSVFileAPIResponse> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			brand_id: brandId,
			content_type: original_content_type,
			encoding: encoding,
			items: [original_name]
		}),
	}
	const response = await fetch(`${basePath}/upload/upload_csv`, params)
	const data = await response.json()
	return { response, data }
}

export const proccessCatalogCSVFileAPI = async ({ brandId, key, token }: TproccessCatalogCSVFileAPIProps): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			brand_id: brandId,
			key: key,
		}),
	}
	const response = await fetch(`${basePath}/upload/upload_csv_data`, params)
	const data = await response.json()
	return { response, data }
}

export const getImagesPatternAPI = async (brandId: string, token: string): Promise<TgetImagesPatternAPIResponse> => {
	const params = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/image_management/get_pattern?brand_id=${brandId}`, params)
	const data = await response.json()
	return { response, data }
}

export const saveImagesPatternAPI = async ({ brandId, separator, pattern, token }: TsaveImagesPatternAPIProps): Promise<any> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			brand_id: brandId,
			separator,
			pattern
		}),
	}
	const response = await fetch(`${basePath}/image_management/save_pattern`, params)
	const data = await response.json()
	return { response, data }
}

export const deleteImagesPatternAPI = async (patternId: string, token: string): Promise<any> => {
	const params = {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	}
	const response = await fetch(`${basePath}/image_management/delete_pattern?id=${patternId}`, params)
	const data = await response.json()
	return { response, data }
}

export const uploadProductImagesAPI = async ({ body, token }: TuploadProductImagesAPIProps): Promise<TuploadProductImagesAPIResponse> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/upload/upload_product_images`, params)
	const data = await response.json()
	return { response, data }
}

export const matchImagesAPI = async ({ brandId, token }: { brandId: string, token: string }): Promise<TmatchImagesAPIResponse> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			brand_id: brandId
		}),
	}
	const response = await fetch(`${basePath}/image_management/match`, params)
	const data = await response.json()
	return { response, data }
}

export const uploadDiscardedImagesAPI = async ({ brandId, discarded_items, token }: { brandId: string, discarded_items: TUploadImagesItem[], token: string }): Promise<TuploadDiscardedImagesAPIResponse> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			brand_id: brandId,
			discarded_items
		}),
	}
	const response = await fetch(`${basePath}/upload/upload_discarded_images`, params)
	const data = await response.json()
	return { response, data }
}

export const getUnmatchedImagesAPI = async ({ brandId, search, index, limit, token }: TgetUnmatchedImagesAPIProps): Promise<TgetUnmatchedImagesAPIResponse> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ brand_id: brandId, search, index, limit }),
	}
	const response = await fetch(`${basePath}/image_management/get_unmatched_images`, params)
	const data = await response.json()
	return { response, data }
}

export const assignImagesAPI = async ({ body, token }: TassignImagesAPIProps): Promise<TassignImagesAPIResponse> => {
	const params = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body),
	}
	const response = await fetch(`${basePath}/product_management/reasign_images`, params)
	const data = await response.json()
	return { response, data }
}