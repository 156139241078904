import { IBrandUser } from "interfaces/brandUsers.interface"
import { ISAdminTenantState, IInitialSuperAdminState } from "interfaces/superAdmin.interface"

const initialSuperAdminState: IInitialSuperAdminState = {
	clients: [] as ISAdminTenantState[],
	isLoading: true,
	removeUserClient: (tenantId: string, userId: string) => { },
	addUserClient: (clientId: string, userId: IBrandUser) => { },
}

export default initialSuperAdminState